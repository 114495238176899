import { useState, useEffect } from "react";
import { Modal } from "antd";

import { useClientsStore } from "../../../store/clients";
import { ProductVertical } from "@omi-lab/ceos-typescript";

export const useListProductVerticals = () => {
  const [verticals, setVerticals] = useState<ProductVertical[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const client = useClientsStore((state) => state.productVerticalsClient);

  useEffect(() => {
    const listProductVerticals = async () => {
      try {
        setIsLoading(true);

        client
          .listProductVerticals({
            page: 1,
            pageSize: 1000,
          })
          .then((response) => response.data)
          .then((verticals) => setVerticals(verticals));
      } catch (error) {
        Modal.error({
          content: "An error occured while listing the verticals.",
        });
      } finally {
        setIsLoading(false);
      }
    };

    listProductVerticals();
  }, [client, setVerticals]);

  return {
    verticals,
    setVerticals,
    isLoading,
  };
};
