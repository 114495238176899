import { useState, useEffect } from "react";
import { ProjectTemplate } from "@omi-lab/atlas-typescript";
import { Modal } from "antd";

import { useClientsStore } from "../../../store/clients";

export const useListProjectTemplates = ({
  page,
  pageSize,
}: {
  page?: number;
  pageSize?: number;
}) => {
  const [projectTemplates, setProjectTemplates] = useState<ProjectTemplate[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);

  const projectTemplatesClient = useClientsStore(
    (state) => state.projectTemplatesClient,
  );

  useEffect(() => {
    const listProjectTemplates = async () => {
      try {
        setIsLoading(true);

        projectTemplatesClient
          .listProjectTemplates({
            page,
            pageSize,
            returnRelatedSceneTemplates: true,
          })
          .then((response) => response.data)
          .then((projectTemplates) => setProjectTemplates(projectTemplates));
      } catch (error) {
        Modal.error({
          content: "An error occured while listing the project templates.",
        });
      } finally {
        setIsLoading(false);
      }
    };

    listProjectTemplates();
  }, [projectTemplatesClient, setProjectTemplates, page, pageSize]);

  return {
    projectTemplates,
    setProjectTemplates,
    isLoading,
  };
};
