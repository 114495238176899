import { FC, useState, useEffect } from "react";
import {
  Card,
  Image,
  Space,
  Popconfirm,
  Select,
  Tooltip,
  Typography,
} from "antd";
import { DeleteOutlined, RollbackOutlined } from "@ant-design/icons";
import {
  ModelObject,
  ObjectCollection,
  ObjectTag,
  ObjectVisibility,
  UpdateObjectBody,
} from "@omi-lab/atlas-typescript";
import { ModelFile } from "@omi-lab/fedex-typescript";

import { useClientsStore } from "../../../store/clients";
import { useSearchTags } from "../hooks/useSearchTags";
import { useSearchCollections } from "../hooks/useSearchCollections";
import debounce from "lodash.debounce";

interface Props {
  isPrivate?: boolean;
  object: ModelObject;
  updateObject: (id: string, body: UpdateObjectBody) => Promise<void>;
  deleteObject: () => Promise<void>;
  collections: ObjectCollection[];
  tags: ObjectTag[];
}

export const ObjectCard: FC<Props> = (props) => {
  const { setSearch: setTagSearch, tags } = useSearchTags();
  const { setSearch: setCollectionSearch, collections } =
    useSearchCollections();
  const [isLoading, setIsLoading] = useState(true);
  const [thumbnail, setThumbnail] = useState<ModelFile>();

  const [isUpdating, setIsUpdating] = useState(false);

  const [filesClient] = useClientsStore((state) => [state.filesClient]);

  useEffect(() => {
    if (!props.object.thumbnailFileId) {
      setIsLoading(false);
      return;
    }

    const getThumbnail = async () =>
      filesClient.getFile({
        fileId: props.object.thumbnailFileId,
        returnRelatedPresignedURL: true,
      });

    getThumbnail()
      .then((response) => setThumbnail(response.data))
      .then(() => setIsLoading(false));
  }, [setThumbnail, filesClient, props.object.thumbnailFileId]);

  const archiveObjectToggle = async () => {
    try {
      setIsUpdating(true);
      await props.updateObject(props.object.id, {
        organizationId: props.object.organizationId,
        visibility: props.object.visibility,
        isArchived: !props.object.isArchived,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const updateObject = async (body: UpdateObjectBody) => {
    try {
      setIsUpdating(true);
      await props.updateObject(props.object.id, body);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Card
      hoverable={false}
      actions={
        props.object.visibility === ObjectVisibility.Private
          ? [
              <Popconfirm
                title={
                  props.object.isArchived
                    ? "Do you want to unarchive this object ?"
                    : "Do you want to archive this object ?"
                }
                onConfirm={archiveObjectToggle}
                okText="Yes"
                okButtonProps={{ loading: isUpdating }}
                cancelText="No"
                disabled={props.object.visibility !== ObjectVisibility.Private}
              >
                {props.object.isArchived ? (
                  <RollbackOutlined
                    key="unarchive"
                    disabled={
                      props.object.visibility !== ObjectVisibility.Private
                    }
                  />
                ) : (
                  <DeleteOutlined
                    key="archive"
                    disabled={
                      props.object.visibility !== ObjectVisibility.Private
                    }
                  />
                )}
              </Popconfirm>,
            ]
          : []
      }
      loading={isLoading}
    >
      <Space
        style={{
          width: "100%",
          height: 430,
          overflowY: "scroll",
        }}
        direction="vertical"
        size="large"
      >
        <Image src={thumbnail?.url!} />
        <Card.Meta
          title={
            <Tooltip title={props.object.name}>
              <span>{props.object.name}</span>
            </Tooltip>
          }
        />
        <Select
          mode="multiple"
          placeholder="Select tags"
          loading={isUpdating}
          onFocus={() => setTagSearch("")}
          style={{ width: "100%" }}
          showArrow={true}
          showSearch
          allowClear
          onSearch={debounce((e) => setTagSearch(e), 100)}
          maxTagCount={10}
          value={(props.object.tags || []).map((tag) => tag.name)}
          onSelect={(key) =>
            updateObject({
              organizationId: props.object.organizationId,
              tags: [
                ...(props.object.tags || [])
                  .filter(({ name }) => name !== key)
                  .map((tag) => tag.name),
                key,
              ],
            })
          }
          onDeselect={(key) =>
            updateObject({
              organizationId: props.object.organizationId,
              tags: (props.object.tags || [])
                .filter(({ name }) => name !== key)
                .map((tag) => tag.name),
            })
          }
        >
          {[
            ...(props.object.tags || []),
            ...tags.filter(
              (t) => !props.object.tags?.find((t1) => t1.name === t.name),
            ),
          ].map((tag) => (
            <Select.Option key={tag.name} value={tag.name}>
              {tag.name}
            </Select.Option>
          ))}
        </Select>
        <Select
          onFocus={() => setCollectionSearch("")}
          mode="multiple"
          showSearch
          allowClear
          onSearch={debounce((e) => setCollectionSearch(e), 100)}
          maxTagCount={10}
          placeholder="Select collections"
          loading={isUpdating}
          style={{ width: "100%" }}
          showArrow={true}
          value={(props.object.collections || []).map(
            (collection) => collection.path,
          )}
          onSelect={(key) =>
            updateObject({
              organizationId: props.object.organizationId,
              collections: [
                ...(props.object.collections || []).filter(
                  ({ path }) => path !== key,
                ),
                collections.find(({ path }) => path === key)!,
              ],
            })
          }
          onDeselect={(key) =>
            updateObject({
              organizationId: props.object.organizationId,
              collections: (props.object.collections || []).filter(
                ({ path }) => path !== key,
              ),
            })
          }
        >
          {[
            ...(props.object.collections || []),
            ...collections.filter(
              (t) =>
                !props.object.collections?.find((t1) => t1.path === t.path),
            ),
          ].map((collection) => (
            <Select.Option key={collection.path} value={collection.path}>
              {collection.name}
            </Select.Option>
          ))}
        </Select>

        <Typography.Text copyable>{props.object.id}</Typography.Text>
      </Space>
    </Card>
  );
};
