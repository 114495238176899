import { useState, useEffect } from "react";
import { Organization } from "@omi-lab/ceos-typescript";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";

export function useSearchOrganizations() {
  const [search, setSearch] = useState("");
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [organizationsClient] = useClientsStore((store) => [
    store.organizationsClient,
  ]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        await organizationsClient
          .listOrganizations({
            nameIncludes: search,
            page: 1,
            pageSize: 30,
          })
          .then((response) => setOrganizations(response.data));
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [organizationsClient, search]);

  return { organizations, search, setSearch, isLoading };
}
