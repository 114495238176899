import { useState, useEffect } from "react";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";
import { Camera } from "@omi-lab/atlas-typescript";

export function useGetCamera(id: string) {
  const [camera, setcamera] = useState<Camera | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const [camerasClient] = useClientsStore((store) => [store.camerasClient]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const camera = (
          await camerasClient.getCamera({
            cameraId: id,
          })
        ).data;

        setcamera(camera);
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id, camerasClient]);

  return { camera, isLoading };
}
