import {
  AppstoreOutlined,
  CameraOutlined,
  ClearOutlined,
  CloudOutlined,
  ClusterOutlined,
  DatabaseOutlined,
  FilterOutlined,
  FormOutlined,
  InstagramOutlined,
  MonitorOutlined,
  PicCenterOutlined,
  PlusOutlined,
  RadarChartOutlined,
  SketchOutlined,
  SlidersOutlined,
  TagsOutlined,
  ToolOutlined,
  TranslationOutlined,
  UngroupOutlined,
  UserOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { SystemAdministratorPermission } from "@omi-lab/ceos-typescript";
import { Menu as AntMenu } from "antd";
import { FC } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { useClientsStore } from "../store/clients";

interface Props extends RouteComponentProps<any> {}

const Menu: FC<Props> = (props) => {
  const { permissions } = useClientsStore((state) => state);

  return (
    <AntMenu
      mode="inline"
      defaultOpenKeys={[]}
      selectedKeys={[props.location.pathname]}
      style={{ paddingTop: "20px", paddingBottom: "20px", height: "100%" }}
    >
      {permissions.includes(
        SystemAdministratorPermission.ManageSystemResources,
      ) && (
        <>
          <AntMenu.SubMenu
            title="Objects"
            icon={<PicCenterOutlined />}
            key="objects"
          >
            <AntMenu.Item icon={<MonitorOutlined />} key="/objects">
              <Link to="/objects">Explore</Link>
            </AntMenu.Item>
            <AntMenu.Item
              icon={<AppstoreOutlined />}
              key="/objects/collections"
            >
              <Link to="/objects/collections">Collections</Link>
            </AntMenu.Item>
          </AntMenu.SubMenu>
          <AntMenu.SubMenu
            title="Templates"
            icon={<SlidersOutlined />}
            key="templates"
          >
            <AntMenu.Item icon={<MonitorOutlined />} key="/templates">
              <Link to="/templates">Explore</Link>
            </AntMenu.Item>
            <AntMenu.Item
              icon={<AppstoreOutlined />}
              key="/templates/collections"
            >
              <Link to="/templates/collections">Collections</Link>
            </AntMenu.Item>
            <AntMenu.Item icon={<AppstoreOutlined />} key="/templates/tags">
              <Link to="/templates/tags">Tags</Link>
            </AntMenu.Item>
            <AntMenu.Item
              icon={<UngroupOutlined />}
              key="/templates/custom-previews"
            >
              <Link to="/templates/custom-previews">Previews</Link>
            </AntMenu.Item>
          </AntMenu.SubMenu>
          <AntMenu.SubMenu
            title="Cameras"
            icon={<InstagramOutlined />}
            key="cameras"
          >
            <AntMenu.Item icon={<MonitorOutlined />} key="/cameras">
              <Link to="/cameras">Explore</Link>
            </AntMenu.Item>
            <AntMenu.Item
              icon={<AppstoreOutlined />}
              key="/cameras/collections"
            >
              <Link to="/cameras/collections">Collections</Link>
            </AntMenu.Item>
          </AntMenu.SubMenu>
          <AntMenu.SubMenu
            title="Light Sets"
            icon={<FilterOutlined />}
            key="light-sets"
          >
            <AntMenu.Item icon={<MonitorOutlined />} key="/light-sets">
              <Link to="/light-sets">Explore</Link>
            </AntMenu.Item>
            <AntMenu.Item
              icon={<AppstoreOutlined />}
              key="/light-sets/collections"
            >
              <Link to="/light-sets/collections">Collections</Link>
            </AntMenu.Item>
          </AntMenu.SubMenu>
          <AntMenu.SubMenu
            title="Materials"
            icon={<ClearOutlined />}
            key="materials"
          >
            <AntMenu.Item icon={<MonitorOutlined />} key="/materials">
              <Link to="/materials">Explore</Link>
            </AntMenu.Item>
            <AntMenu.Item
              icon={<AppstoreOutlined />}
              key="/materials/collections"
            >
              <Link to="/materials/collections">Collections</Link>
            </AntMenu.Item>
          </AntMenu.SubMenu>
          <AntMenu.SubMenu
            title="SkyBoxes"
            icon={<CloudOutlined />}
            key="skyBoxes"
          >
            <AntMenu.Item icon={<MonitorOutlined />} key="/sky-boxes">
              <Link to="/sky-boxes">Explore</Link>
            </AntMenu.Item>
            <AntMenu.Item
              icon={<AppstoreOutlined />}
              key="/sky-boxes/collections"
            >
              <Link to="/sky-boxes/collections">Collections</Link>
            </AntMenu.Item>
          </AntMenu.SubMenu>
          <AntMenu.SubMenu
            title="Projects"
            icon={<DatabaseOutlined />}
            key="projects"
          >
            <AntMenu.Item icon={<SketchOutlined />} key="/projects/templates">
              <Link to="/projects/templates">Templates</Link>
            </AntMenu.Item>
          </AntMenu.SubMenu>
          <AntMenu.SubMenu
            title="Translations"
            icon={<TranslationOutlined />}
            key="translations"
          >
            <AntMenu.Item icon={<MonitorOutlined />} key="/translations">
              <Link to="/translations">Translations</Link>
            </AntMenu.Item>
          </AntMenu.SubMenu>
        </>
      )}
      <AntMenu.SubMenu title="Renders" icon={<CameraOutlined />} key="renders">
        <AntMenu.Item icon={<MonitorOutlined />} key="/renders">
          <Link to="/renders">Explore</Link>
        </AntMenu.Item>
      </AntMenu.SubMenu>

      <AntMenu.Item icon={<MonitorOutlined />} key="/schedule-events">
        <Link to="/schedule-events">Schedule events</Link>
      </AntMenu.Item>
      <AntMenu.Item icon={<MonitorOutlined />} key="/format-presets">
        <Link to="/format-presets">Format presets</Link>
      </AntMenu.Item>
      {permissions.includes(
        SystemAdministratorPermission.ManageOrganizations,
      ) && (
        <>
          <AntMenu.SubMenu
            title="Organizations"
            icon={<ClusterOutlined />}
            key="organizations"
          >
            <AntMenu.Item icon={<MonitorOutlined />} key="/organizations">
              <Link to="/organizations">Explore</Link>
            </AntMenu.Item>
            <AntMenu.Item
              icon={<RadarChartOutlined />}
              key="/product-verticals"
            >
              <Link to="/product-verticals">Product verticals</Link>
            </AntMenu.Item>
          </AntMenu.SubMenu>
        </>
      )}
      {permissions.includes(SystemAdministratorPermission.ManagePlans) && (
        <>
          <AntMenu.SubMenu
            title="Billing"
            icon={<WalletOutlined />}
            key="billing"
          >
            <AntMenu.Item icon={<MonitorOutlined />} key="/billing/products">
              <Link to="/billing/products">Explore</Link>
            </AntMenu.Item>
            <AntMenu.Item
              icon={<PlusOutlined />}
              key="/billing/products/create"
            >
              <Link to="/billing/products/create">New</Link>
            </AntMenu.Item>
            <AntMenu.Item icon={<TagsOutlined />} key="/billing/coupons">
              <Link to="/billing/coupons">Coupons</Link>
            </AntMenu.Item>
            <AntMenu.Item icon={<FormOutlined />} key="/billing/quotes">
              <Link to="/billing/quotes">Quotes</Link>
            </AntMenu.Item>
          </AntMenu.SubMenu>
        </>
      )}
      {permissions.includes(
        SystemAdministratorPermission.ManagePermissions,
      ) && (
        <AntMenu.SubMenu title="System" icon={<ToolOutlined />} key="system">
          <AntMenu.Item icon={<UserOutlined />} key="/system-administrators">
            <Link to="/system-administrators">Administrators</Link>
          </AntMenu.Item>
        </AntMenu.SubMenu>
      )}
    </AntMenu>
  );
};

export default withRouter(Menu);
