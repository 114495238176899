import {
  CameraCollectionsApi,
  CamerasApi,
  CameraTagsApi,
  LightSetsApi,
  LightSetCollectionsApi,
  LightSetTagsApi,
  MaterialCollectionsApi,
  MaterialsApi,
  MaterialTagsApi,
  ObjectCollectionsApi,
  ObjectsApi,
  ObjectTagsApi,
  ProductsApi as AtlasProductApi,
  ProjectsApi,
  ProjectTemplatesApi,
  SceneTemplateCollectionsApi,
  SceneTemplatesApi,
  SceneTemplateTagsApi,
  SkyBoxCollectionsApi,
  SkyBoxesApi,
  SkyBoxTagsApi,
  TextContentsApi,
  SceneTemplateCustomPreviewsApi,
  Class360sApi,
  ScheduledEventsApi,
  FormatPresetSlugsApi,
  FormatPresetsApi,
} from "@omi-lab/atlas-typescript";
import {
  AuthenticationApi,
  OrganizationsApi,
  SystemAdministratorPermission,
  SystemAdministratorsApi,
  ProductVerticalsApi,
} from "@omi-lab/ceos-typescript";
import {
  CustomersApi,
  PlansApi,
  SubscriptionsApi,
  OrganizationsApi as CresusOrganizationsApi,
  RatesApi,
  SubscriptionScheduledUpdatesApi,
  ProductsApi,
  CouponsApi,
  QuotesApi,
} from "@omi-lab/cresus-typescript";
import { FilesApi } from "@omi-lab/fedex-typescript";
import { ProductPhotosApi, RendersApi } from "@omi-lab/studio-typescript";
import axios, { AxiosInstance } from "axios";
import create from "zustand";
import { store } from "./methods";

type State = {
  renders: RendersApi;
  axios: AxiosInstance;
  permissions: SystemAdministratorPermission[];
  auth: AuthenticationApi;
  organizationsClient: OrganizationsApi;
  productVerticalsClient: ProductVerticalsApi;
  objectCollectionsClient: ObjectCollectionsApi;
  projectsClient: ProjectsApi;
  projectTemplatesClient: ProjectTemplatesApi;
  sceneTemplatesClient: SceneTemplatesApi;
  sceneTemplateCollectionsClient: SceneTemplateCollectionsApi;
  objectTagsClient: ObjectTagsApi;
  sceneTemplateTagsClient: SceneTemplateTagsApi;
  objectsClient: ObjectsApi;
  filesClient: FilesApi;
  camerasClient: CamerasApi;
  cameraCollectionsClient: CameraCollectionsApi;
  cameraTagsClient: CameraTagsApi;
  lightSetsClient: LightSetsApi;
  lightSetCollectionsClient: LightSetCollectionsApi;
  lightSetTagsClient: LightSetTagsApi;
  materialsClient: MaterialsApi;
  materialCollectionsClient: MaterialCollectionsApi;
  materialTagsClient: MaterialTagsApi;
  plansClient: PlansApi;
  atlasProductsClient: AtlasProductApi;
  customersClient: CustomersApi;
  couponsClient: CouponsApi;
  subscriptionsClient: SubscriptionsApi;
  subscriptionScheduledUpdatesClient: SubscriptionScheduledUpdatesApi;
  skyBoxesClient: SkyBoxesApi;
  skyBoxCollectionsClient: SkyBoxCollectionsApi;
  skyBoxTagsClient: SkyBoxTagsApi;
  sceneTemplateCustomPreviewClient: SceneTemplateCustomPreviewsApi;
  systemAdministratorsClient: SystemAdministratorsApi;
  threeSixtiesClient: Class360sApi;
  cresusOrganizationsClient: CresusOrganizationsApi;
  ratesClient: RatesApi;
  productPhotosClient: ProductPhotosApi;
  productsClient: ProductsApi;
  quotesClient: QuotesApi;
  textContentsClient: TextContentsApi;
  token: string | null;
  scheduledEventsClient: ScheduledEventsApi;
  formatPresetsClient: FormatPresetsApi;
  formatPresetSlugsClient: FormatPresetSlugsApi;
  setToken: (token: string) => void;
  setPermissions: (permissions: SystemAdministratorPermission[]) => void;
};

const axiosInstance = axios.create({
  withCredentials: true,
});

export const useClientsStore = create<State>(
  store(
    (set) => ({
      token: null,
      axios: axiosInstance,
      permissions: [],
      setPermissions: (permissions) => {
        set(() => {
          return { permissions };
        });
      },
      setToken: (token) => {
        set(() => {
          axiosInstance.defaults.headers.common["Authorization"] =
            `Bearer ${token}`;

          return { token };
        });
      },
      renders: new RendersApi(
        undefined,
        process.env.REACT_APP_STUDIO_API_URL,
        axiosInstance,
      ),
      projectsClient: new ProjectsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      projectTemplatesClient: new ProjectTemplatesApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      sceneTemplatesClient: new SceneTemplatesApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      sceneTemplateCollectionsClient: new SceneTemplateCollectionsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      auth: new AuthenticationApi(
        undefined,
        process.env.REACT_APP_CEOS_API_URL,
        axiosInstance,
      ),
      organizationsClient: new OrganizationsApi(
        undefined,
        process.env.REACT_APP_CEOS_API_URL,
        axiosInstance,
      ),
      productVerticalsClient: new ProductVerticalsApi(
        undefined,
        process.env.REACT_APP_CEOS_API_URL,
        axiosInstance,
      ),
      objectsClient: new ObjectsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      sceneTemplateTagsClient: new SceneTemplateTagsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      objectTagsClient: new ObjectTagsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      filesClient: new FilesApi(
        undefined,
        process.env.REACT_APP_FEDEX_API_URL,
        axiosInstance,
      ),
      camerasClient: new CamerasApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      cameraCollectionsClient: new CameraCollectionsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      cameraTagsClient: new CameraTagsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      cresusOrganizationsClient: new CresusOrganizationsApi(
        undefined,
        process.env.REACT_APP_CRESUS_API_URL,
        axiosInstance,
      ),
      lightSetsClient: new LightSetsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      lightSetCollectionsClient: new LightSetCollectionsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      lightSetTagsClient: new LightSetTagsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      objectCollectionsClient: new ObjectCollectionsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      materialsClient: new MaterialsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      materialTagsClient: new MaterialTagsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      materialCollectionsClient: new MaterialCollectionsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      subscriptionsClient: new SubscriptionsApi(
        undefined,
        process.env.REACT_APP_CRESUS_API_URL,
        axiosInstance,
      ),
      subscriptionScheduledUpdatesClient: new SubscriptionScheduledUpdatesApi(
        undefined,
        process.env.REACT_APP_CRESUS_API_URL,
        axiosInstance,
      ),
      plansClient: new PlansApi(
        undefined,
        process.env.REACT_APP_CRESUS_API_URL,
        axiosInstance,
      ),
      customersClient: new CustomersApi(
        undefined,
        process.env.REACT_APP_CRESUS_API_URL,
        axiosInstance,
      ),
      couponsClient: new CouponsApi(
        undefined,
        process.env.REACT_APP_CRESUS_API_URL,
        axiosInstance,
      ),
      ratesClient: new RatesApi(
        undefined,
        process.env.REACT_APP_CRESUS_API_URL,
        axiosInstance,
      ),
      productsClient: new ProductsApi(
        undefined,
        process.env.REACT_APP_CRESUS_API_URL,
        axiosInstance,
      ),
      skyBoxesClient: new SkyBoxesApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      skyBoxTagsClient: new SkyBoxTagsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      skyBoxCollectionsClient: new SkyBoxCollectionsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      systemAdministratorsClient: new SystemAdministratorsApi(
        undefined,
        process.env.REACT_APP_CEOS_API_URL,
        axiosInstance,
      ),
      sceneTemplateCustomPreviewClient: new SceneTemplateCustomPreviewsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      threeSixtiesClient: new Class360sApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      productPhotosClient: new ProductPhotosApi(
        undefined,
        process.env.REACT_APP_STUDIO_API_URL,
        axiosInstance,
      ),
      quotesClient: new QuotesApi(
        undefined,
        process.env.REACT_APP_CRESUS_API_URL,
        axiosInstance,
      ),
      textContentsClient: new TextContentsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      atlasProductsClient: new AtlasProductApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      scheduledEventsClient: new ScheduledEventsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      formatPresetSlugsClient: new FormatPresetSlugsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
      formatPresetsClient: new FormatPresetsApi(
        undefined,
        process.env.REACT_APP_ATLAS_API_URL,
        axiosInstance,
      ),
    }),
    "@@auth",
  ),
);
