import { FC } from "react";

import { useGetOrganizations } from "../hooks/useGetOrganization";

interface Props {
  id?: string;
}

export const OrganizationName: FC<Props> = (props) => {
  const { organization } = useGetOrganizations(props?.id);

  return <p>{organization?.name}</p>;
};
