import { Button, Col, Popconfirm, Row } from "antd";
import { FC, useState } from "react";
import { UserSwitchOutlined } from "@ant-design/icons";
import { Organization } from "@omi-lab/ceos-typescript";
import { useClientsStore } from "../../../store/clients";

interface Props {
  organization: Organization;
  deleteOrganization: (id: string) => Promise<void>;
}

export const OrganizationActions: FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const authClient = useClientsStore((store) => store.auth);

  const deleteOrganization = async () => {
    if (!props.organization.id) {
      return;
    }

    setIsLoading(true);
    await props.deleteOrganization(props.organization.id);
    setIsLoading(false);
  };

  const switchContext = async () => {
    if (!props.organization.id) {
      return;
    }

    await authClient.switchContext({
      body: {
        organizationId: props.organization.id,
      },
    });

    window.open(process.env.REACT_APP_MANAGE_URL, "_blank")?.focus();
  };

  return (
    <Row gutter={[0, 0]}>
      <Col span={12}>
        <Button icon={<UserSwitchOutlined />} onClick={switchContext}></Button>
      </Col>
      <Col span={12}>
        <Popconfirm
          title="Are you sure to delete this organization ?"
          onConfirm={deleteOrganization}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary" danger loading={isLoading}>
            Delete
          </Button>
        </Popconfirm>
      </Col>
    </Row>
  );
};
