import { notification, Checkbox, Form, Badge, DatePicker } from "antd";
import { FC, useState, useEffect } from "react";
import { Customer, Subscription } from "@omi-lab/cresus-typescript";
import { Organization } from "@omi-lab/ceos-typescript";
import dayjs from "dayjs";

import { useClientsStore } from "../../../store/clients";
import { ClockCircleFilled } from "@ant-design/icons";

interface Props {
  organization: Organization;
  setWasOnboarded(wasOnboarded: boolean): Promise<void>;
}

export const OrganizationSubscriptionStatus: FC<Props> = (props) => {
  const [customer, setCustomer] = useState<Customer | undefined>();
  const [subscription, setSubscription] = useState<Subscription | undefined>();

  const [customersClient, cresusOrganizationsClient] = useClientsStore(
    (store) => [store.customersClient, store.cresusOrganizationsClient],
  );

  useEffect(() => {
    if (!props.organization.id) {
      return;
    }

    const getCustomer = async () => {
      try {
        await customersClient
          .listCustomers({
            organizationId: props.organization.id,
          })
          .then((response) =>
            setCustomer(response.data.length ? response.data[0] : undefined),
          );
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error.message,
        });
      }
    };

    getCustomer();
  }, [props.organization.id, customersClient]);

  useEffect(() => {
    if (!props.organization.id) {
      return;
    }

    const getActiveSubscription = async () => {
      try {
        const subscription = await cresusOrganizationsClient
          .getOrganizationActiveSubscription({
            organizationId: props.organization.id,
            returnRelatedQuotaUsages: true,
            returnRelatedCreditUsageSummary: true,
          })
          .then((response) => response.data);
        setSubscription(subscription);
      } catch (error: any) {
        if (error?.response?.status !== 404) {
          notification.error({
            message: error?.response?.data?.error || error.message,
          });
        }
      }
    };

    getActiveSubscription();
  }, [props.organization.id, cresusOrganizationsClient]);

  return (
    <Form layout="inline">
      <Form.Item label="Onboarded">
        <Checkbox
          checked={props.organization.wasOnboarded}
          onChange={(e) => props.setWasOnboarded(e.target.checked)}
        />
      </Form.Item>
      <Form.Item label="Customer">
        <Checkbox checked={!!customer} />
      </Form.Item>
      <Form.Item label="Subscription">
        {subscription?.canceledAt ? (
          <Badge count={<ClockCircleFilled style={{ color: "#f5222d" }} />}>
            <Checkbox checked={!!subscription} />
          </Badge>
        ) : (
          <Checkbox checked={!!subscription} />
        )}
      </Form.Item>
      <Form.Item label="Since">
        <DatePicker value={dayjs(subscription?.createdAt)} disabled />
      </Form.Item>
      <Form.Item
        label={
          !subscription?.canceledAt && subscription?.endsAt
            ? "Next payment date"
            : "Subscription ends"
        }
      >
        <DatePicker value={dayjs(subscription?.endsAt)} disabled />
      </Form.Item>
    </Form>
  );
};
