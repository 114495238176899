import { notification, Row, Button, Space } from "antd";
import { FC, useState } from "react";

import { useClientsStore } from "../../../store/clients";

interface Props {
  quoteId: string;
}

export const GetLink: FC<Props> = (props) => {
  const [isGeneratingToken, setIsGeneratingToken] = useState(false);

  const [quotesClient] = useClientsStore((store) => [store.quotesClient]);

  const generateLink = async () => {
    try {
      setIsGeneratingToken(true);

      const token = await quotesClient
        .getQuoteToken({
          quoteId: props.quoteId,
        })
        .then(
          (response) =>
            `${process.env.REACT_APP_MANAGE_URL}/organization-settings?quoteId=${props.quoteId}&token=${response.data}&modal=QUOTES_MODAL`,
        );

      navigator.clipboard.writeText(token);

      notification.success({
        message: "Successfully copied the token.",
      });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || error.message,
      });
    } finally {
      setIsGeneratingToken(false);
    }
  };

  return (
    <Row>
      <Space direction="horizontal" style={{ width: "100%" }}>
        <Button
          type="primary"
          loading={isGeneratingToken}
          disabled={isGeneratingToken}
          onClick={() => generateLink()}
        >
          Copy link
        </Button>
      </Space>
    </Row>
  );
};
