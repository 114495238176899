import { useState, useEffect } from "react";
import {
  IntervalUnit,
  Product,
  ProductVisibility,
} from "@omi-lab/cresus-typescript";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";

export function useListProductAddons(params: {
  productId: string;
  nameIncludes?: string;
  visibility?: ProductVisibility[];
  billingInterval?: number;
  billingIntervalUnit?: IntervalUnit;
  commitmentInterval?: number;
  commitmentIntervalUnit?: IntervalUnit;
  page?: number;
  pageSize?: number;
}) {
  const [addons, setAddons] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);

  const client = useClientsStore((store) => store.productsClient);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await client.listProductAddons({
          productId: params.productId,
          nameIncludes: params.nameIncludes,
          page: params.page,
          pageSize: params.pageSize,
          visibility: params.visibility,
          returnRelatedFeatures: true,
          returnRelatedTiers: true,
        });

        setAddons(data);

        if (data.length === params.pageSize) {
          setPageCount((params.page || 0) + 1);
        }
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    client,
    params.productId,
    params.nameIncludes,
    params.visibility,
    params.page,
    params.pageSize,
  ]);

  return { addons, setAddons, isLoading, pageCount };
}
