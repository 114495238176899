import {
  Language,
  ListScheduledEventsOrderByEnum,
  ListScheduledEventsResponseBody,
  SceneTemplateCollectionsOrderBy,
  ScheduledEvent,
  ScheduledEventsApiCreateScheduledEventRequest,
  ScheduledEventsApiUpdateScheduledEventRequest,
  Translation,
  UpdateTextContentBody,
  SceneTemplateCollection,
} from "@omi-lab/atlas-typescript";

import { Dropzone } from "src/components/Dropzone";

import Compressor from "compressorjs";

import * as Yup from "yup";

import {
  Button,
  Col,
  DatePicker,
  Flex,
  Form,
  Image,
  Input,
  List,
  Modal,
  Pagination,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Spin,
  notification,
} from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useFile } from "src/hooks/useFile";
import { useClientsStore } from "src/store/clients";
import dayjs from "dayjs";
import { useGetTextContents } from "./Templates/hooks/useGetTextContents";

type TemplateCollectionSelectProps = {
  setCurrentSceneTemplateCollection: (
    sceneTemplateCollectionId: string,
  ) => void;
  defaultValue?: string;
};

export const TemplateCollectionSelect = ({
  setCurrentSceneTemplateCollection,
  defaultValue,
}: TemplateCollectionSelectProps) => {
  const client = useClientsStore(
    (state) => state.sceneTemplateCollectionsClient,
  );

  const [sceneTemplateCollections, setSceneTemplateCollections] =
    useState<SceneTemplateCollection[]>();

  useEffect(() => {
    const listSceneTemplateCollections = async () => {
      const { data } = await client.listSceneTemplateCollectionsV2({
        page: 1,
        pageSize: 200,
        templateLoadCount: 200,
        orderBy: [SceneTemplateCollectionsOrderBy.WeightAsc],
      });

      setSceneTemplateCollections(data.data);
    };

    listSceneTemplateCollections();
  }, [client]);

  return (
    <Select
      placeholder="Select template collection"
      defaultValue={defaultValue}
      onSelect={(value: string) => {
        setCurrentSceneTemplateCollection(value);
      }}
    >
      {sceneTemplateCollections?.map((sceneTemplateCollection) => (
        <Select.Option
          key={sceneTemplateCollection.path}
          value={sceneTemplateCollection.path}
        >
          {sceneTemplateCollection.name}
        </Select.Option>
      ))}
    </Select>
  );
};

type CreateScheduleEventModalProps = {
  isOpen: boolean;
  handleCreateScheduleEvent: (
    body: ScheduledEventsApiCreateScheduledEventRequest,
  ) => Promise<void>;
  close: () => void;
};

export const validationCreateScheduleEventScheme = Yup.object().shape({
  name: Yup.string()
    .min(3, "Name should be at least 3 characters long")
    .required("A name is required"),
  enableAt: Yup.date().required("A date is required"),
  startsAt: Yup.date().required("A date is required"),
  disableAfter: Yup.date().optional().nullable(),
  sceneTemplateCollectionPath: Yup.string().required(),
  thumbnailFileId: Yup.string().required(),
});

const CreateScheduleEventModal = ({
  isOpen,
  close,
  handleCreateScheduleEvent,
}: CreateScheduleEventModalProps) => {
  const filesClient = useClientsStore((state) => state.filesClient);

  const [uploadFile, setUploadFile] = useState<File>();
  const [loading, setLoading] = useState(false);

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      enableAt: "",
      startsAt: "",
      disableAfter: "",
      sceneTemplateCollectionPath: "",
      thumbnailFileId: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await handleCreateScheduleEvent({
          body: {
            ...values,
            disableAfter: dayjs(values.disableAfter).toDate(),
            enableAt: dayjs(values.enableAt).toDate(),
            startsAt: dayjs(values.startsAt).toDate(),
          },
        });
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }

      resetForm();
      close();
    },
    validationSchema: validationCreateScheduleEventScheme,
    validateOnMount: false,
  });

  const handleOnCancel = () => {
    resetForm();
    close();
  };

  const uploadThumbnailFile = async (file: File) => {
    const { data } = await filesClient.uploadFile({
      file,
      name: file.name,
    });

    setValues({
      ...values,
      thumbnailFileId: data.id,
    });
  };

  const handleOnDrop = async (files: File[]) => {
    new Compressor(files[0], {
      maxWidth: 562,
      success: async (compressed: File) => {
        setUploadFile(compressed);
        uploadThumbnailFile(compressed);
      },
      error: (error) => {
        notification.error({
          message: error.message,
        });
      },
    });
  };

  const submitAndClose = () => {
    handleSubmit();
  };

  return (
    <Modal
      title="Create schedule event"
      open={isOpen}
      onCancel={handleOnCancel}
      onOk={submitAndClose}
      okButtonProps={{ loading }}
    >
      <Col style={{ padding: "15px" }}>
        <Form initialValues={values}>
          <Form.Item
            label="Name"
            name="name"
            help={touched?.name && errors?.name}
            validateStatus={touched?.name && errors?.name ? "error" : "success"}
          >
            <Input
              value={values.name}
              onChange={(e) =>
                setValues({
                  ...values,
                  name: e.target.value,
                })
              }
              onBlur={handleBlur}
            />
          </Form.Item>

          <Form.Item
            label="Path"
            name="sceneTemplateCollectionPath"
            help={
              touched.sceneTemplateCollectionPath &&
              errors.sceneTemplateCollectionPath
            }
            validateStatus={
              touched.sceneTemplateCollectionPath &&
              errors.sceneTemplateCollectionPath
                ? "error"
                : "success"
            }
          >
            <TemplateCollectionSelect
              setCurrentSceneTemplateCollection={(
                sceneTemplateCollectionPath,
              ) => {
                setValues((values) => ({
                  ...values,
                  sceneTemplateCollectionPath,
                }));
              }}
            />
          </Form.Item>

          <Form.Item
            label="Enable at"
            name="enableAt"
            help={touched.enableAt && errors.enableAt}
            validateStatus={
              touched.enableAt && errors.enableAt ? "error" : "success"
            }
          >
            <DatePicker
              onChange={(date) => {
                if (!date) return;

                setValues((values) => ({
                  ...values,
                  enableAt: date?.toString(),
                }));
              }}
            />
          </Form.Item>

          <Form.Item
            label="Starts at"
            name="startsAt"
            help={touched.startsAt && errors.startsAt}
            validateStatus={
              touched.startsAt && errors.startsAt ? "error" : "success"
            }
          >
            <DatePicker
              onChange={(date) => {
                if (!date) return;

                setValues((values) => ({
                  ...values,
                  startsAt: date?.toString(),
                }));
              }}
            />
          </Form.Item>

          <Form.Item
            label="Disable after"
            name="disableAfter"
            help={touched.disableAfter && errors.disableAfter}
            validateStatus={
              touched.disableAfter && errors.disableAfter ? "error" : "success"
            }
          >
            <DatePicker
              onChange={(date) => {
                if (!date) return;

                setValues((values) => ({
                  ...values,
                  disableAfter: date?.toString(),
                }));
              }}
            />
          </Form.Item>

          <Form.Item
            label="Thumbnail file"
            name="thumbnailFileId"
            help={touched.thumbnailFileId && errors.thumbnailFileId}
            validateStatus={
              touched.thumbnailFileId && errors.thumbnailFileId
                ? "error"
                : "success"
            }
          >
            <Dropzone
              onDrop={handleOnDrop}
              files={uploadFile ? [uploadFile] : []}
              accept="image/*"
              maxFiles={1}
            />
          </Form.Item>
        </Form>
      </Col>
    </Modal>
  );
};

type UpdateScheduleEventTranslationModalProps = {
  isOpen: boolean;
  close: () => void;
  textContentId: string;
};

const UpdateScheduleEventTranslationModal = ({
  isOpen,
  close,
  textContentId,
}: UpdateScheduleEventTranslationModalProps) => {
  const client = useClientsStore((state) => state.textContentsClient);
  const [isLoading, setIsLoading] = useState(false);

  const {
    isLoading: textContentLoading,
    setTextContent,
    textContent,
  } = useGetTextContents(textContentId);

  const updateTranslation = async (
    id: string,
    language: Language,
    translation: string,
  ) => {
    if (textContent === undefined) return;
    setTextContent({
      ...textContent,
      translations: [
        ...(textContent?.translations?.filter(
          (translation) => translation.languageId !== language,
        ) || []),
        {
          languageId: language,
          translation: translation,
          textContentId: id,
        } as Translation,
      ],
    });
  };

  const updateTextContent = async (id: string, body: UpdateTextContentBody) => {
    try {
      setIsLoading(true);
      await client.updateTextContent({
        textContentId: id,
        body,
        returnRelatedTranslations: true,
      });

      notification.success({
        message: "The text content was successfully updated.",
        duration: 4,
      });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    updateTextContent(textContentId, {
      translations:
        textContent?.translations?.map((translation) => ({
          languageId: translation.languageId,
          translation: translation.translation,
        })) || [],
    });

    close();
  };

  if (textContentLoading) return <Spin />;

  return (
    <Modal
      title="Update schedule event translation"
      open={isOpen}
      onCancel={close}
      onOk={handleSubmit}
      okButtonProps={{ loading: isLoading }}
    >
      <Col style={{ padding: "15px" }}>
        <Form.Item label="French">
          <Input
            placeholder="French"
            type="text"
            value={
              textContent?.translations?.find(
                ({ languageId }) => languageId === Language.Fr,
              )?.translation ?? textContent?.originalText
            }
            onChange={(e) =>
              updateTranslation(textContentId, Language.Fr, e.target.value)
            }
          />
        </Form.Item>

        <Form.Item label="English">
          <Input
            placeholder="English"
            type="text"
            value={
              textContent?.translations?.find(
                ({ languageId }) => languageId === Language.En,
              )?.translation
            }
            onChange={(e) =>
              updateTranslation(textContentId, Language.En, e.target.value)
            }
          />
        </Form.Item>
        <Form.Item label="Spanish">
          <Input
            placeholder="Spanish"
            type="text"
            value={
              textContent?.translations?.find(
                ({ languageId }) => languageId === Language.Es,
              )?.translation
            }
            onChange={(e) =>
              updateTranslation(textContentId, Language.Es, e.target.value)
            }
          />
        </Form.Item>
      </Col>
    </Modal>
  );
};

type UpdateScheduleEventModalProps = {
  isOpen: boolean;
  handleUpdateScheduleEvent: (
    body: ScheduledEventsApiUpdateScheduledEventRequest,
  ) => Promise<void>;
  scheduleEvent: ScheduledEvent;
  close: () => void;
};

export const validationUpdateScheduleEventScheme = Yup.object().shape({
  name: Yup.string()
    .min(3, "Name should be at least 3 characters long")
    .required("A name is required"),
  enableAt: Yup.date().required("A date is required"),
  startsAt: Yup.date().required("A date is required"),
  disableAfter: Yup.date().optional().nullable(),
  sceneTemplateCollectionPath: Yup.string().required(),
  thumbnailFileId: Yup.string().required(),
});

const UpdateScheduleEventModal = ({
  isOpen,
  close,
  handleUpdateScheduleEvent,
  scheduleEvent,
}: UpdateScheduleEventModalProps) => {
  const filesClient = useClientsStore((state) => state.filesClient);

  const [uploadFile, setUploadFile] = useState<File>();
  const [loading, setLoading] = useState(false);

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: {
      ...scheduleEvent,
      scheduledEventId: scheduleEvent.id,
      enableAt: dayjs(scheduleEvent.enableAt),
      startsAt: dayjs(scheduleEvent.startsAt),
      disableAfter: dayjs(scheduleEvent.disableAfter),
    },
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await handleUpdateScheduleEvent({
          scheduledEventId: values.scheduledEventId,
          body: {
            ...values,
            enableAt: dayjs(values.enableAt).toDate(),
            startsAt: dayjs(values.startsAt).toDate(),
            disableAfter: dayjs(values.disableAfter).toDate(),
          },
        });
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
      resetForm();
      close();
    },
    validationSchema: validationUpdateScheduleEventScheme,
    validateOnMount: false,
  });

  const handleOnCancel = () => {
    resetForm();
    close();
  };

  const uploadThumbnailFile = async (file: File) => {
    const { data } = await filesClient.uploadFile({
      file,
      name: file.name,
    });

    setValues({
      ...values,
      thumbnailFileId: data.id,
    });
  };

  const handleOnDrop = async (files: File[]) => {
    new Compressor(files[0], {
      maxWidth: 562,
      success: async (compressed: File) => {
        setUploadFile(compressed);
        uploadThumbnailFile(compressed);
      },
      error: (error) => {
        notification.error({
          message: error.message,
        });
      },
    });
  };

  const submitAndClose = () => {
    handleSubmit();
  };

  return (
    <Modal
      title="Update schedule event"
      open={isOpen}
      onCancel={handleOnCancel}
      onOk={submitAndClose}
      okButtonProps={{ loading }}
    >
      <Col style={{ padding: "15px" }}>
        <Form initialValues={values}>
          <Form.Item
            label="Name"
            name="name"
            help={touched?.name && errors?.name}
            validateStatus={touched.name && errors.name ? "error" : "success"}
          >
            <Input
              value={values.name ?? ""}
              onChange={(e) =>
                setValues({
                  ...values,
                  name: e.target.value,
                })
              }
              onBlur={handleBlur}
            />
          </Form.Item>

          <Form.Item
            label="Path"
            name="sceneTemplateCollectionPath"
            help={
              touched.sceneTemplateCollectionPath &&
              errors.sceneTemplateCollectionPath
            }
            validateStatus={
              touched.sceneTemplateCollectionPath &&
              errors.sceneTemplateCollectionPath
                ? "error"
                : "success"
            }
          >
            <TemplateCollectionSelect
              defaultValue={values.sceneTemplateCollectionPath ?? undefined}
              setCurrentSceneTemplateCollection={(
                sceneTemplateCollectionPath,
              ) => {
                setValues((values) => ({
                  ...values,
                  sceneTemplateCollectionPath,
                }));
              }}
            />
          </Form.Item>

          <Form.Item
            label="Enable at"
            name="enableAt"
            help={touched.enableAt && (errors.enableAt as string)}
            validateStatus={
              touched.enableAt && errors.enableAt ? "error" : "success"
            }
          >
            <DatePicker
              onChange={(date) => {
                if (!date) return;

                setValues((values) => ({
                  ...values,
                  enableAt: date,
                }));
              }}
            />
          </Form.Item>

          <Form.Item
            label="Starts at"
            name="startsAt"
            help={touched.startsAt && (errors.startsAt as string)}
            validateStatus={
              touched.startsAt && errors.startsAt ? "error" : "success"
            }
          >
            <DatePicker
              onChange={(date) => {
                if (!date) return;

                setValues((values) => ({
                  ...values,
                  startsAt: date,
                }));
              }}
            />
          </Form.Item>

          <Form.Item
            label="Disable after"
            name="disableAfter"
            help={touched.disableAfter && (errors.disableAfter as string)}
            validateStatus={
              touched.disableAfter && errors.disableAfter ? "error" : "success"
            }
          >
            <DatePicker
              onChange={(date) => {
                if (!date) return;

                setValues((values) => ({
                  ...values,
                  disableAfter: date,
                }));
              }}
            />
          </Form.Item>

          <Form.Item
            label="Thumbnail file"
            name="thumbnailFileId"
            help={touched.thumbnailFileId && errors.thumbnailFileId}
            validateStatus={
              touched.thumbnailFileId && errors.thumbnailFileId
                ? "error"
                : "success"
            }
          >
            <Dropzone
              onDrop={handleOnDrop}
              files={uploadFile ? [uploadFile] : []}
              accept="image/*"
              maxFiles={1}
            />
          </Form.Item>
        </Form>
      </Col>
    </Modal>
  );
};

type ScheduleEventImgProps = {
  thumbnailId?: string;
};

const ScheduleEventImg = ({ thumbnailId }: ScheduleEventImgProps) => {
  const { file, isLoading } = useFile(thumbnailId, {
    returnRelatedCategories: true,
  });

  const url = file?.url ?? undefined;

  const isIdle = !isLoading || !url;

  if (isIdle) {
    return <Skeleton.Image />;
  }

  return <Image height={140} alt="thumbnail" src={url} />;
};

type ScheduleEventCardProps = {
  data: ScheduledEvent;
  deleteScheduleEvent: (id: string) => Promise<void>;
  updateScheduleEvent: (
    body: ScheduledEventsApiUpdateScheduledEventRequest,
  ) => Promise<void>;
};

const ScheduleEventCard = ({
  data,
  deleteScheduleEvent,
  updateScheduleEvent,
}: ScheduleEventCardProps) => {
  const [isUpdateScheduleEventModal, setIsUpdateScheduleEventModal] =
    useState(false);

  const openUpdateScheduleEventModal = () =>
    setIsUpdateScheduleEventModal(true);

  const closeUpdateScheduleEventModal = () =>
    setIsUpdateScheduleEventModal(false);

  const [
    isUpdateScheduleEventTranslationModal,
    setIsUpdateScheduleEventTranslationModal,
  ] = useState(false);

  const openUpdateScheduleEventTranslationModal = () => {
    setIsUpdateScheduleEventTranslationModal(true);
  };

  const closeUpdateScheduleEventTranslationModal = () => {
    setIsUpdateScheduleEventTranslationModal(false);
  };

  return (
    <>
      <List.Item
        key={data.id}
        title={data.name}
        actions={[
          <Popconfirm
            title="Delete the schedule event ?"
            description="Are you sure to delete this event ?"
            onConfirm={() => deleteScheduleEvent(data.id)}
            okText="Confirm"
            cancelText="Cancel"
          >
            <Button danger>Delete</Button>,
          </Popconfirm>,
          <Space>
            <Button onClick={openUpdateScheduleEventTranslationModal}>
              Update translation
            </Button>
            <Button onClick={openUpdateScheduleEventModal}>Update</Button>
          </Space>,
        ]}
        extra={
          <ScheduleEventImg thumbnailId={data.thumbnailFileId ?? undefined} />
        }
      >
        <List.Item.Meta
          title={data.name}
          description={`Collection: ${data.sceneTemplateCollectionPath}`}
        />

        <div>
          <p>Enable at: {new Date(data.enableAt).toDateString()}</p>
          <p>Starts at: {new Date(data.startsAt).toDateString()}</p>
          <p>
            {`Disable after: ${
              data.disableAfter
                ? new Date(data.disableAfter).toDateString()
                : "N/A"
            }`}
          </p>
        </div>
      </List.Item>
      <UpdateScheduleEventModal
        key={isUpdateScheduleEventModal ? "open" : "close"}
        isOpen={isUpdateScheduleEventModal}
        close={closeUpdateScheduleEventModal}
        handleUpdateScheduleEvent={updateScheduleEvent}
        scheduleEvent={data}
      />
      <UpdateScheduleEventTranslationModal
        key={
          isUpdateScheduleEventTranslationModal
            ? "open-translation"
            : "close-translation"
        }
        isOpen={isUpdateScheduleEventTranslationModal}
        close={closeUpdateScheduleEventTranslationModal}
        textContentId={data.nameTextContent?.id ?? ""}
      />
    </>
  );
};

const PAGE_SIZE = 10;

export const ScheduleEvents = () => {
  const client = useClientsStore((state) => state.scheduledEventsClient);

  const [page, setPage] = useState(1);
  const [scheduleEvents, setScheduleEvents] =
    useState<ListScheduledEventsResponseBody>();

  const [isCreateScheduleEventModal, setIsCreateScheduleEventModal] =
    useState(false);

  const opencreateScheduleEventModal = () =>
    setIsCreateScheduleEventModal(true);

  const closeCreateScheduleEventModal = () =>
    setIsCreateScheduleEventModal(false);

  const handleCreateScheduleEvent = async (
    body: ScheduledEventsApiCreateScheduledEventRequest,
  ) => {
    try {
      const { data } = await client.createScheduledEvent({
        ...body,
        returnRelatedNameTextContent: true,
        returnRelatedNameTranslation: true,
      });
      setScheduleEvents((prevScheduleEvents) => ({
        ...prevScheduleEvents,
        count: prevScheduleEvents?.count ? prevScheduleEvents.count + 1 : 1,
        hasMore: prevScheduleEvents?.hasMore ?? true,
        data: [...(scheduleEvents?.data ?? []), data],
      }));
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleRemoveScheduleEvent = async (id: string) => {
    try {
      await client.deleteScheduledEvent({ scheduledEventId: id });

      setScheduleEvents((prevScheduleEvents) => ({
        ...prevScheduleEvents,
        count: prevScheduleEvents?.count ? prevScheduleEvents.count - 1 : 0,
        hasMore: prevScheduleEvents?.hasMore ?? true,
        data:
          prevScheduleEvents?.data?.filter(
            (scheduleEvent) => scheduleEvent.id !== id,
          ) ?? [],
      }));
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleUpdateScheduleEvent = async (
    body: ScheduledEventsApiUpdateScheduledEventRequest,
  ) => {
    try {
      const { data } = await client.updateScheduledEvent({ ...body });

      setScheduleEvents((prevScheduleEvents) => ({
        ...prevScheduleEvents,
        count: prevScheduleEvents?.count ? prevScheduleEvents.count + 1 : 1,
        hasMore: prevScheduleEvents?.hasMore ?? true,
        data:
          prevScheduleEvents?.data?.map((scheduleEvent) => {
            if (scheduleEvent.id === body.scheduledEventId) {
              return data;
            }

            return scheduleEvent;
          }) ?? [],
      }));
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    const listScheduleEvents = async () =>
      client
        .listScheduledEvents({
          page,
          pageSize: PAGE_SIZE,
          orderBy: [ListScheduledEventsOrderByEnum.FromNowAsc],
          returnRelatedSceneTemplateCollection: true,
          returnRelatedNameTextContent: true,
          returnRelatedNameTranslation: true,
        })
        .then((response) => {
          setScheduleEvents(response.data);
        });

    listScheduleEvents();
  }, [client, page]);

  return (
    <>
      <Flex gap="middle" vertical style={{ padding: "1rem" }}>
        <div className="flex w-full">
          <Button onClick={opencreateScheduleEventModal}>
            Create a new schedule events
          </Button>
        </div>
        <List
          itemLayout="vertical"
          size="large"
          dataSource={scheduleEvents?.data ?? []}
          key={JSON.stringify(scheduleEvents)}
          footer={
            <Pagination
              current={page}
              pageSize={PAGE_SIZE}
              total={scheduleEvents?.count ?? 0}
              onChange={setPage}
            />
          }
          renderItem={(item) => (
            <ScheduleEventCard
              deleteScheduleEvent={handleRemoveScheduleEvent}
              updateScheduleEvent={handleUpdateScheduleEvent}
              data={item}
            />
          )}
        />
      </Flex>
      <CreateScheduleEventModal
        key={isCreateScheduleEventModal ? "open" : "close"}
        isOpen={isCreateScheduleEventModal}
        close={closeCreateScheduleEventModal}
        handleCreateScheduleEvent={handleCreateScheduleEvent}
      />
    </>
  );
};

export default ScheduleEvents;
