import {
  List,
  Col,
  Select,
  Row,
  Form,
  Pagination,
  notification,
  Button,
} from "antd";
import { useState } from "react";
import {
  ScenePreviewStatus,
  SceneTemplateCustomPreviewsApiCreateSceneTemplateCustomPreviewWorkflowRequest,
} from "@omi-lab/atlas-typescript";

import { useListSceneTemplateCustomPreviews } from "../hooks";
import { OrganizationSearch } from "../components/OrganizationSearch";
import { TemplateCustomPreviewCard } from "../components/TemplateCustomPreviewCard";
import { useClientsStore } from "../../../store/clients";
import { SceneTemplateSearch } from "../components/SceneTemplateSearch";

import { TemplateCustomPreviewConfirmWorkflowCreationModal } from "../components/TemplateCustomPreviewConfirmWorkflowCreationModal";

const StatusMap: Record<ScenePreviewStatus, string> = {
  [ScenePreviewStatus.NUMBER_0]: "Pending",
  [ScenePreviewStatus.NUMBER_1]: "In progress",
  [ScenePreviewStatus.NUMBER_2]: "Failed",
  [ScenePreviewStatus.NUMBER_3]: "Success",
};

export const TemplateCustomPreviews = () => {
  const [status, setStatus] = useState<ScenePreviewStatus>();
  const [organizationId, setOrganizationId] = useState<string>();
  const [sceneTemplateId, setSceneTemplateId] = useState<string>();
  const [rescheduledWorkflows, setRescheduledWorkflows] = useState<string[]>(
    [],
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  const client = useClientsStore(
    (store) => store.sceneTemplateCustomPreviewClient,
  );

  const {
    page,
    setPage,
    pageSize,
    setPageSize,
    count,
    isLoading,
    previews,
    setPreviews,
  } = useListSceneTemplateCustomPreviews({
    organizationId,
    status,
    sceneTemplateId,
  });

  const rescheduleWorkflow = async (workflowId: string) => {
    try {
      const { data: id } =
        await client.resubmitSceneTemplateCustomPreviewWorkflow({
          workflowId,
        });

      setPreviews((previews) =>
        previews
          .map((preview) =>
            preview.workflowId === workflowId
              ? {
                  ...preview,
                  status: ScenePreviewStatus.NUMBER_0,
                  workflowId: id,
                }
              : preview,
          )
          .filter((preview) => !status || preview.status === status),
      );
      setRescheduledWorkflows((workflows) => [...workflows, id]);
      notification.success({
        message: "Successfully rescheduled the workflow.",
      });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || error.message,
      });
    }
  };

  const createSceneTemplateCustomPreview = async (
    params: SceneTemplateCustomPreviewsApiCreateSceneTemplateCustomPreviewWorkflowRequest,
  ) => {
    try {
      const { data: ids } =
        await client.createSceneTemplateCustomPreviewWorkflow(params);

      notification.success({
        message: `Successfully created workflows: ${ids.join(",")}.`,
      });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || error.message,
      });
    }
  };

  return (
    <Col style={{ padding: "15px" }}>
      <List grid={{ gutter: 16 }} loading={isLoading}>
        <List.Item>
          <Row gutter={16} justify="space-between">
            <Col span={6}>
              <Form.Item name="organization">
                <OrganizationSearch
                  onChange={setOrganizationId}
                  onClear={() => setOrganizationId(undefined)}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="template">
                <SceneTemplateSearch
                  onChange={setSceneTemplateId}
                  onClear={() => setSceneTemplateId(undefined)}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="status">
                <Select
                  placeholder="Status"
                  onClear={() => setStatus(undefined)}
                  allowClear
                  onChange={(status) => setStatus(status as ScenePreviewStatus)}
                >
                  {Object.values(ScenePreviewStatus).map((status) => (
                    <Select.Option value={status}>
                      {StatusMap[status]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="add">
                <Button onClick={() => setIsModalVisible(true)}>+</Button>
              </Form.Item>
            </Col>
          </Row>
        </List.Item>
        <List.Item>
          <List
            grid={{
              gutter: 10,
              xs: 1,
              sm: 2,
              md: 3,
              lg: 4,
              xl: 5,
              xxl: 9,
            }}
            dataSource={previews}
            renderItem={(preview) => (
              <List.Item>
                <TemplateCustomPreviewCard
                  key={preview.id}
                  preview={preview}
                  rescheduledWorkflows={rescheduledWorkflows}
                  rescheduleWorkflow={rescheduleWorkflow}
                />
              </List.Item>
            )}
          />
          <Pagination
            style={{ marginTop: 20 }}
            key={count}
            current={page}
            onChange={setPage}
            pageSize={pageSize}
            total={count}
            onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
            showSizeChanger={true}
          />
        </List.Item>
      </List>
      {isModalVisible && (
        <TemplateCustomPreviewConfirmWorkflowCreationModal
          close={() => setIsModalVisible(false)}
          isVisible={isModalVisible}
          createSceneTemplateCustomPreview={createSceneTemplateCustomPreview}
        />
      )}
    </Col>
  );
};
