import { useState, useEffect } from "react";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";
import { LightSet } from "@omi-lab/atlas-typescript";

export function useGetLightSet(id: string) {
  const [lightSet, setlightSet] = useState<LightSet | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const [lightSetsClient] = useClientsStore((store) => [store.lightSetsClient]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const lightSet = (
          await lightSetsClient.getLightSet({
            lightSetId: id,
          })
        ).data;

        setlightSet(lightSet);
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id, lightSetsClient]);

  return { lightSet, isLoading };
}
