import { useState, useEffect } from "react";
import { Product } from "@omi-lab/cresus-typescript";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";

export function useGetProduct(id?: string) {
  const [product, setProduct] = useState<Product>();
  const [isLoading, setIsLoading] = useState(false);

  const [productsClient] = useClientsStore((store) => [store.productsClient]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (!id) {
          return;
        }

        const { data } = await productsClient.getProduct({
          productId: id,
          returnRelatedFeatures: true,
          returnRelatedAddons: true,
        });

        setProduct(data);
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [productsClient, id]);

  return { product, setProduct, isLoading };
}
