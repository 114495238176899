import { FC, useState } from "react";
import { Form, Input, Col, Select, Button, Divider, Space, Modal } from "antd";
import { useFormik } from "formik";
import {
  PlanFeatureName,
  ProductType,
  ProductVisibility,
  CreateProductAddonBody,
  ProductSlug,
} from "@omi-lab/cresus-typescript";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ValidationScheme } from "./CreateAddonModal.schema";

interface Props {
  isOpen: boolean;
  close: () => void;
  productId: string;
  createAddon: (id: string, body: CreateProductAddonBody) => Promise<void>;
}

export const CreateAddonModal: FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    values,
    setValues,
    handleChange,
    handleBlur,
    resetForm,
    errors,
    touched,
    setTouched,
  } = useFormik({
    initialValues: {
      name: "",
      slug: ProductSlug.Videos,
      type: ProductType.SkuAddonUnits,
      visibility: ProductVisibility.Public,
      features: [],
    } as CreateProductAddonBody,
    onSubmit: (values) => console.log(values),
    validateOnMount: false,
    validationSchema: ValidationScheme,
  });

  const createAddon = async () => {
    try {
      setIsLoading(true);

      await props.createAddon(props.productId, values);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="Create addon"
      width="50%"
      open={props.isOpen}
      okButtonProps={{
        disabled: isLoading,
      }}
      onOk={() => createAddon()}
      onCancel={() => {
        resetForm();
        props.close();
      }}
    >
      <Col
        style={{
          backgroundColor: "#fafafa",
          border: "1px dashed #d9d9d9",
          alignItems: "center",
          padding: "30px",
        }}
      >
        <Form initialValues={values}>
          <Form.Item
            label="Name"
            name="name"
            help={touched.name && errors.name}
            validateStatus={touched.name && errors.name ? "error" : "success"}
          >
            <Input
              defaultValue={values.name}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Form.Item>
          <Form.Item
            label="Slug"
            name="slug"
            help={touched.slug && errors.slug}
            validateStatus={touched.slug && errors.slug ? "error" : "success"}
          >
            <Select
              value={values.slug}
              defaultValue={values.slug}
              onChange={(value) =>
                setValues({
                  ...values,
                  slug: value,
                })
              }
              onBlur={() =>
                setTouched({
                  ...touched,
                  slug: true,
                })
              }
            >
              {Object.values(ProductSlug).map((value) => (
                <Select.Option value={value}>{value}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Type"
            name={`type`}
            help={touched.type && errors.type}
            validateStatus={touched.type && errors.type ? "error" : "success"}
          >
            <Select
              value={values.type}
              defaultValue={values.type}
              onChange={(value) =>
                setValues({
                  ...values,
                  type: value,
                })
              }
              onBlur={() =>
                setTouched({
                  ...touched,
                  type: true,
                })
              }
            >
              {Object.values(ProductType).map((value) => (
                <Select.Option value={value}>{value}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Visibility"
            name={`visibility`}
            help={touched.visibility && errors.visibility}
            validateStatus={
              touched.visibility && errors.visibility ? "error" : "success"
            }
          >
            <Select
              value={values.visibility}
              defaultValue={values.visibility}
              onChange={(value) =>
                setValues({
                  ...values,
                  visibility: value,
                })
              }
              onBlur={() =>
                setTouched({
                  ...touched,
                  visibility: true,
                })
              }
            >
              {Object.values(ProductVisibility).map((value) => (
                <Select.Option value={value}>{value}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          {values.type === ProductType.SkuAddon ||
          values.type === ProductType.SkuAddonFixed ||
          values.type === ProductType.SkuAddonUnits ? (
            <Form.Item
              label="Unit amount in cents"
              name={`unitAmount`}
              help={touched.unitAmount && errors.unitAmount}
              validateStatus={
                touched.unitAmount && errors.unitAmount ? "error" : "success"
              }
            >
              <Input
                disabled={values.tiers && values.tiers.length > 0}
                type="number"
                defaultValue={values.unitAmount!}
                value={values.unitAmount!}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
          ) : null}
          {values.type === ProductType.SkuAddonTiered ? (
            <Form.Item label="Tiers">
              {values.tiers?.map((tier, i) => (
                <Form.Item label={`Tier #${i}`}>
                  <Space>
                    <Form.Item label="Up to" name={`tiers.${i}.upTo`}>
                      <Input
                        type="number"
                        defaultValue={tier.upTo!}
                        value={tier.upTo!}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Unit amount in cents"
                      name={`tiers.${i}.unitAmount`}
                    >
                      <Input
                        type="number"
                        defaultValue={tier.unitAmount!}
                        value={tier.unitAmount!}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() =>
                        setValues({
                          ...values,
                          tiers: values.tiers?.filter((_, k) => k !== i),
                        })
                      }
                    />
                    <Divider />
                  </Space>
                </Form.Item>
              ))}
              <Button
                type="dashed"
                onClick={() =>
                  setValues({
                    ...values,
                    tiers: [
                      ...(values.tiers || []),
                      {
                        upTo: undefined,
                        unitAmount: 100,
                      },
                    ],
                  })
                }
                block
                icon={<PlusOutlined />}
              >
                Add a new tier
              </Button>
            </Form.Item>
          ) : null}
          <Form.Item
            label="Features"
            name={`features`}
            help={touched.features && errors.features}
            validateStatus={
              touched.features && errors.features ? "error" : "success"
            }
          >
            <Select
              mode="tags"
              onSelect={(name: PlanFeatureName) =>
                setValues({
                  ...values,
                  features: [...(values.features || []), name],
                })
              }
              onDeselect={(name: PlanFeatureName) => {
                setValues({
                  ...values,
                  features: values.features.filter((n) => name !== n),
                });
              }}
              value={values.features as PlanFeatureName[]}
              defaultValue={values.features as PlanFeatureName[]}
            >
              {Object.values(PlanFeatureName).map((value) => (
                <Select.Option value={value}>{value}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Col>
    </Modal>
  );
};
