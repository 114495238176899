import { useState, useEffect } from "react";
import { SceneTemplate } from "@omi-lab/atlas-typescript";
import { Modal } from "antd";

import { useClientsStore } from "../../../store/clients";

export const useListSceneTemplates = ({
  nameIncludes,
  page,
  pageSize,
}: {
  nameIncludes?: string;
  page?: number;
  pageSize?: number;
}) => {
  const [sceneTemplates, setSceneTemplates] = useState<SceneTemplate[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const sceneTemplatesClient = useClientsStore(
    (state) => state.sceneTemplatesClient,
  );

  useEffect(() => {
    const listSceneTemplates = async () => {
      try {
        setIsLoading(true);

        sceneTemplatesClient
          .listSceneTemplates({
            nameIncludes,
            page,
            pageSize,
          })
          .then((response) => response.data)
          .then((sceneTemplates) => setSceneTemplates(sceneTemplates));
      } catch (error) {
        Modal.error({
          content: "An error occured while listing the scene templates.",
        });
      } finally {
        setIsLoading(false);
      }
    };

    listSceneTemplates();
  }, [sceneTemplatesClient, setSceneTemplates, nameIncludes, page, pageSize]);

  return {
    sceneTemplates,
    setSceneTemplates,
    isLoading,
  };
};
