import {
  Pagination,
  Table,
  Divider,
  message,
  Button,
  Space,
  Popconfirm,
  Tag,
  Col,
  List,
  Row,
  Select,
} from "antd";
import { FC, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Quote } from "@omi-lab/cresus-typescript";
import { format } from "date-fns";

import { useClientsStore } from "../../../store/clients";

import { QuoteCreate } from "../components/QuoteCreate";
import { useListQuotes } from "../hooks/useListQuotes";
import { QuoteStatus } from "@omi-lab/cresus-typescript";
import { OrganizationName } from "../components/OrganizationName";
import { GetLink } from "../components/GetLink";
import { SendLink } from "../components/SendLink";
import { OrganizationSearch } from "../components/OrganizationSearch";

interface Props extends RouteComponentProps<any> {}

export const Quotes: FC<Props> = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [pageCount, setPageCount] = useState(1);
  const [organizationId, setOrganizationId] = useState<string>();
  const [status, setStatus] = useState<QuoteStatus>();

  const { quotes, setQuotes, isLoading } = useListQuotes({
    page,
    pageSize,
    organizationId,
    status,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (quotes.length >= pageSize) {
      setPageCount(page + 1);
    }
  }, [quotes, pageSize, setPageCount, page]);

  const quotesClient = useClientsStore((state) => state.quotesClient);

  const deleteQuote = async (id: string) => {
    try {
      setIsDeleting(true);
      await quotesClient.deleteQuote({
        quoteId: id,
      });
      message.success("The quote was successfully deleted");

      setQuotes(quotes.filter((quote) => quote.id !== id));
    } catch (error: any) {
      message.error(error?.response?.data?.error || error.message);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Col style={{ padding: "15px" }}>
      <List grid={{ gutter: 16 }}>
        <List.Item>
          <Row gutter={16} justify="start">
            <Col span={6}>
              <OrganizationSearch
                onClear={() => setOrganizationId(undefined)}
                onChange={(id) => setOrganizationId(id)}
              />
            </Col>
            <Col span={6}>
              <Select
                allowClear
                style={{ width: "100%" }}
                placeholder="Status"
                filterOption={false}
                defaultValue={status}
                onChange={(e) => setStatus(e)}
                onClear={() => setStatus(undefined)}
              >
                {Object.values(QuoteStatus).map((status) => (
                  <Select.Option value={status} key={status}>
                    {status}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        </List.Item>
        <List.Item>
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            Create a quote
          </Button>
        </List.Item>
        <List.Item>
          <Table
            dataSource={quotes}
            loading={isLoading}
            pagination={false}
            columns={[
              {
                title: "ID",
                key: "id",
                dataIndex: "id",
                width: "10%",
              },
              {
                title: "Organization",
                key: "organizationId",
                render: (_, record) => (
                  <OrganizationName id={record.organizationId || undefined} />
                ),
                width: "10%",
              },
              {
                title: "Status",
                key: "status",
                dataIndex: "status",
                width: "10%",
              },
              {
                title: "Expires at",
                key: "expiresAt",
                dataIndex: "expiresAt",
                render: (_, record: Quote) => (
                  <p>
                    {record.expiresAt
                      ? format(
                          new Date(record.expiresAt),
                          "yyyy-MM-dd HH:mm:ss",
                        )
                      : "Never"}
                  </p>
                ),
                width: "10%",
              },
              {
                title: "Coupon",
                key: "coupon",
                render: (_, record: Quote) => (
                  <p>{record.coupon?.percentOff} %</p>
                ),
                width: "10%",
              },
              {
                title: "Product",
                key: "product",
                render: (_, record: Quote) => (
                  <p>
                    {record.productInstance?.product?.name} (
                    {record.productInstance?.unitCount} SKUs)
                  </p>
                ),
                width: "10%",
              },
              {
                title: "Addons",
                key: "addons",
                render: (_, record: Quote) => (
                  <p>
                    {record.productInstance?.addons?.map((addon) => (
                      <Tag>{addon.product?.name}</Tag>
                    ))}
                  </p>
                ),
                width: "5%",
              },
              {
                title: "Created at",
                key: "createdAt",
                dataIndex: "createdAt",
                render: (_, record: Quote) => (
                  <p>
                    {format(new Date(record.createdAt!), "yyyy-MM-dd HH:mm:ss")}
                  </p>
                ),
                width: "10%",
              },
              {
                title: "Action",
                key: "action",
                render: (_, record: Quote) => (
                  <Space>
                    {record.status === QuoteStatus.PendingApproval && (
                      <Popconfirm
                        title="Are you sure you want to delete this quote ?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteQuote(record.id)}
                        okButtonProps={{ loading: isDeleting }}
                      >
                        <Button type="primary" disabled={isDeleting}>
                          Delete
                        </Button>
                      </Popconfirm>
                    )}
                  </Space>
                ),
                width: "5%",
              },
              {
                title: "Link",
                key: "get",
                render: (_, record: Quote) => <GetLink quoteId={record.id} />,
                width: "5%",
              },
              {
                title: "Email",
                key: "send",
                render: (_, record: Quote) => <SendLink quoteId={record.id} />,
                width: "15%",
              },
            ]}
          />
          <Divider />
          <Pagination
            current={page}
            onChange={setPage}
            total={(pageCount - 1) * pageSize + quotes.length}
            onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
            showSizeChanger={true}
          />
        </List.Item>
      </List>
      {isModalVisible && (
        <QuoteCreate
          close={(quote) => {
            setIsModalVisible(false);

            if (quote) {
              return setQuotes([
                ...quotes.filter(({ id }) => id !== quote.id),
                quote,
              ]);
            }
          }}
        />
      )}
    </Col>
  );
};
