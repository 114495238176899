import { useState, useEffect } from "react";
import { Organization } from "@omi-lab/ceos-typescript";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";

export function useListOrganizations(ids?: string[]) {
  const [organizations, setOrganizations] = useState<
    Record<string, Organization>
  >({});
  const [isLoading, setIsLoading] = useState(false);

  const [organizationsClient] = useClientsStore((store) => [
    store.organizationsClient,
  ]);

  useEffect(() => {
    (async () => {
      if (!ids || ids.length === 0) {
        return;
      }
      setIsLoading(true);
      try {
        const { data } = await organizationsClient.listOrganizationsV2({
          ids: ids,
          pageSize: ids?.length,
          page: 1,
        });

        setOrganizations(
          data.data.reduce(
            (acc, organization) => ({
              ...acc,
              [organization.id]: organization,
            }),
            {},
          ),
        );
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [organizationsClient, ids]);

  return { organizations, setOrganizations, isLoading };
}
