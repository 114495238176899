import { useState, useEffect } from "react";
import { ProductInstance } from "@omi-lab/cresus-typescript";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";

export function useGetProductInstanceQuote(body: {
  productId?: string;
  unitCount?: number;
  customerId?: string;
  couponId?: string;
  addons?: {
    productId: string;
    unitCount?: number;
  }[];
}) {
  const [instance, setInstance] = useState<ProductInstance>();
  const [isLoading, setIsLoading] = useState(false);

  const [productsClient] = useClientsStore((store) => [store.productsClient]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (!body.productId || !body.unitCount) {
          return null;
        }

        const { data } = await productsClient.getProductInstanceQuote({
          body: {
            productId: body.productId,
            unitCount: body.unitCount,
            customerId: body.customerId,
            couponId: body.couponId,
            addons: body.addons?.map((addon) => ({
              productId: addon.productId,
              unitCount: addon.unitCount,
            })),
          },
        });

        setInstance(data);
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { instance, setInstance, isLoading };
}
