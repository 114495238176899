import { FC } from "react";
import { Form, Input, Col, Select, Button, Divider, Space } from "antd";
import has from "lodash.has";
import { ErrorMessage, useFormikContext } from "formik";
import {
  PlanFeatureName,
  CreateProductBody,
  ProductType,
  ProductVisibility,
  ProductSlug,
} from "@omi-lab/cresus-typescript";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

interface Props {}

export const Addons: FC<Props> = () => {
  const { values, handleChange, setValues, errors, touched, handleBlur } =
    useFormikContext<CreateProductBody>();

  return (
    <Col
      style={{
        backgroundColor: "#fafafa",
        border: "1px dashed #d9d9d9",
        alignItems: "center",
        padding: "30px",
      }}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        initialValues={values}
      >
        {(values.addons || []).map((addon, index) => (
          <>
            <Form.Item
              label="Name"
              name={`addons.${index}.name`}
              help={<ErrorMessage name={`addons.${index}.name`} />}
              validateStatus={
                touched.addons &&
                errors.addons &&
                errors.addons[index] &&
                has(errors, `addons.${index}.name`)
                  ? "error"
                  : "success"
              }
            >
              <Input
                defaultValue={addon.name}
                value={addon.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
            <Form.Item
              label="Slug"
              name={`addons.${index}.slug`}
              help={<ErrorMessage name={`addons.${index}.slug`} />}
              validateStatus={
                touched.addons &&
                errors.addons &&
                errors.addons[index] &&
                has(errors, `addons.${index}.slug`)
                  ? "error"
                  : "success"
              }
            >
              <Select
                value={addon.slug}
                defaultValue={addon.slug}
                onChange={(value) =>
                  setValues({
                    ...values,
                    addons: (values.addons || []).map((a, i) => {
                      if (i === index) {
                        return { ...a, slug: value };
                      }
                      return a;
                    }),
                  })
                }
              >
                {["videos", "360s"].map((value) => (
                  <Select.Option value={value}>{value}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Type"
              name={`addons.${index}.type`}
              help={<ErrorMessage name={`addons.${index}.type`} />}
              validateStatus={
                touched.addons &&
                errors.addons &&
                errors.addons[index] &&
                has(errors, `addons.${index}.type`)
                  ? "error"
                  : "success"
              }
            >
              <Select
                value={addon.type}
                defaultValue={addon.type}
                onChange={(value) =>
                  setValues({
                    ...values,
                    addons: (values.addons || []).map((a, i) => {
                      if (i === index) {
                        return { ...a, type: value };
                      }
                      return a;
                    }),
                  })
                }
              >
                {Object.values([
                  ProductType.SkuAddon,
                  ProductType.SkuAddonTiered,
                ]).map((value) => (
                  <Select.Option value={value}>{value}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Visibility"
              name={`addons.${index}.visibility`}
              help={<ErrorMessage name={`addons.${index}.visibility`} />}
              validateStatus={
                touched.addons &&
                errors.addons &&
                errors.addons[index] &&
                has(errors, `addons.${index}.visibility`)
                  ? "error"
                  : "success"
              }
            >
              <Select
                disabled
                value={addon.visibility}
                defaultValue={addon.visibility}
                onChange={(value) =>
                  setValues({
                    ...values,
                    addons: (values.addons || []).map((a, i) => {
                      if (i === index) {
                        return { ...a, visibility: value };
                      }
                      return a;
                    }),
                  })
                }
              >
                {Object.values(ProductVisibility).map((value) => (
                  <Select.Option value={value}>{value}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            {addon.type === ProductType.SkuAddon ||
            addon.type === ProductType.SkuAddonFixed ||
            addon.type === ProductType.SkuAddonUnits ? (
              <Form.Item
                label="Unit amount in cents"
                name={`addons.${index}.unitAmount`}
                help={<ErrorMessage name={`addons.${index}.unitAmount`} />}
                validateStatus={
                  touched.addons &&
                  errors.addons &&
                  errors.addons[index] &&
                  has(errors, `addons.${index}.unitAmount`)
                    ? "error"
                    : "success"
                }
              >
                <Input
                  disabled={addon.tiers && addon.tiers.length > 0}
                  type="number"
                  defaultValue={addon.unitAmount!}
                  value={addon.unitAmount!}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Item>
            ) : null}
            {addon.type === ProductType.SkuAddonTiered ? (
              <Form.Item label="Tiers">
                {addon.tiers?.map((tier, i) => (
                  <Form.Item label={`Tier #${i}`}>
                    <Space>
                      <Form.Item
                        label="Up to"
                        name={`addons.${index}.tiers.${i}.upTo`}
                        help={
                          <ErrorMessage
                            name={`addons.${index}.tiers.${i}.upTo`}
                          />
                        }
                        validateStatus={
                          touched.addons &&
                          errors.addons &&
                          errors.addons[index] &&
                          has(errors, `addons.${index}.tiers.${i}.upTo`)
                            ? "error"
                            : "success"
                        }
                      >
                        <Input
                          type="number"
                          defaultValue={tier.upTo!}
                          value={tier.upTo!}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Unit amount in cents"
                        name={`addons.${index}.tiers.${i}.unitAmount`}
                        help={
                          <ErrorMessage
                            name={`addons.${index}.tiers.${i}.unitAmount`}
                          />
                        }
                        validateStatus={
                          touched.addons &&
                          errors.addons &&
                          errors.addons[index] &&
                          has(errors, `addons.${index}.tiers.${i}.unitAmount`)
                            ? "error"
                            : "success"
                        }
                      >
                        <Input
                          type="number"
                          defaultValue={tier.unitAmount!}
                          value={tier.unitAmount!}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        onClick={() =>
                          setValues({
                            ...values,
                            addons: (values.addons || []).map((a, j) => {
                              if (j === index) {
                                return {
                                  ...a,
                                  tiers: addon.tiers?.filter((_, k) => k !== i),
                                };
                              }
                              return a;
                            }),
                          })
                        }
                      />
                      <Divider />
                    </Space>
                  </Form.Item>
                ))}
                <Button
                  type="dashed"
                  onClick={() =>
                    setValues({
                      ...values,
                      addons: (values.addons || []).map((a, j) => {
                        if (j === index) {
                          return {
                            ...a,
                            tiers: [
                              ...(a.tiers || []),
                              {
                                upTo: undefined,
                                unitAmount: 100,
                              },
                            ],
                          };
                        }
                        return a;
                      }),
                    })
                  }
                  block
                  icon={<PlusOutlined />}
                >
                  Add a new tier
                </Button>
              </Form.Item>
            ) : null}
            <Form.Item
              label="Features"
              name={`addons.${index}.features`}
              help={<ErrorMessage name={`addons.${index}.features`} />}
              validateStatus={
                touched.addons &&
                errors.addons &&
                errors.addons[index] &&
                has(errors, `addons.${index}.features`)
                  ? "error"
                  : "success"
              }
            >
              <Select
                mode="tags"
                onSelect={(name: PlanFeatureName) =>
                  setValues({
                    ...values,
                    addons: (values.addons || []).map((a, i) => {
                      if (i === index) {
                        return {
                          ...a,
                          features: [...(addon.features || []), name],
                        };
                      }
                      return a;
                    }),
                  })
                }
                onDeselect={(_: PlanFeatureName) => {
                  setValues({
                    ...values,
                    addons: (values.addons || []).map((a, i) => {
                      if (i === index) {
                        return {
                          ...a,
                          features: addon.features.filter((_, j) => i !== j),
                        };
                      }
                      return a;
                    }),
                  });
                }}
                value={addon.features as PlanFeatureName[]}
                defaultValue={addon.features as PlanFeatureName[]}
              >
                {Object.values(PlanFeatureName).map((value) => (
                  <Select.Option value={value}>{value}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <MinusCircleOutlined
              onClick={() =>
                setValues({
                  ...values,
                  addons: (values.addons || []).filter(
                    (_, idx) => idx !== index,
                  ),
                })
              }
            />
            <Divider />
          </>
        ))}
        <Form.Item>
          <Button
            type="dashed"
            onClick={() =>
              setValues({
                ...values,
                addons: [
                  ...(values.addons || []),
                  {
                    name: "",
                    slug: ProductSlug.Videos,
                    type: ProductType.SkuAddon,
                    visibility: ProductVisibility.Public,
                    unitAmount: undefined,
                    features: [],
                    tiers: undefined,
                  },
                ],
              })
            }
            block
            icon={<PlusOutlined />}
          >
            Add
          </Button>
        </Form.Item>
      </Form>
    </Col>
  );
};
