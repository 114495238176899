import {
  TextContent,
  UpdateTextContentBody,
  Language,
} from "@omi-lab/atlas-typescript";
import { Input, Modal } from "antd";
import { useFormik } from "formik";
import { FC, useState } from "react";
import { useClientsStore } from "../../../store/clients";

interface Props {
  textContent: TextContent;
  visible: boolean;
  hide: () => void;
}

export const TemplateEditTranslationsModal: FC<Props> = (props) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const client = useClientsStore((store) => store.textContentsClient);

  const { values, setValues, submitForm } = useFormik({
    initialValues: {
      translations: props.textContent.translations,
    } as UpdateTextContentBody,
    onSubmit: async (body) => {
      try {
        setIsUpdating(true);

        await client.updateTextContent({
          textContentId: props.textContent.id,
          body,
          returnRelatedTranslations: true,
        });
      } catch (error) {
        Modal.error({
          content: "An error occured while updating the translations.",
        });
      } finally {
        setIsUpdating(false);
        props.hide();
      }
    },
  });

  return (
    <Modal
      okText="Update"
      open={props.visible}
      okButtonProps={{
        loading: isUpdating,
      }}
      onOk={submitForm}
      onCancel={props.hide}
      cancelButtonProps={{
        loading: isUpdating,
      }}
      style={{ width: "100%" }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <label style={{ marginRight: 10 }}>FR</label>
        <Input
          value={
            values.translations.find(
              ({ languageId }) => languageId === Language.Fr,
            )?.translation
          }
          onChange={(e) =>
            setValues((values) => ({
              ...values,
              translations: [
                ...values.translations.filter(
                  ({ languageId }) => languageId !== Language.Fr,
                ),
                { languageId: Language.Fr, translation: e.target.value },
              ],
            }))
          }
        />
        <label style={{ marginRight: 10 }}>EN</label>
        <Input
          value={
            values.translations.find(
              ({ languageId }) => languageId === Language.En,
            )?.translation
          }
          onChange={(e) =>
            setValues((values) => ({
              ...values,
              translations: [
                ...values.translations.filter(
                  ({ languageId }) => languageId !== Language.En,
                ),
                { languageId: Language.En, translation: e.target.value },
              ],
            }))
          }
        />
        <label style={{ marginRight: 10 }}>ES</label>
        <Input
          value={
            values.translations.find(
              ({ languageId }) => languageId === Language.Es,
            )?.translation
          }
          onChange={(e) =>
            setValues((values) => ({
              ...values,
              translations: [
                ...values.translations.filter(
                  ({ languageId }) => languageId !== Language.Es,
                ),
                { languageId: Language.Es, translation: e.target.value },
              ],
            }))
          }
        />
      </div>
    </Modal>
  );
};
