import { useState, useEffect } from "react";
import {
  IntervalUnit,
  Product,
  ProductType,
  ProductVisibility,
} from "@omi-lab/cresus-typescript";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";

export function useListProducts(params: {
  nameIncludes?: string;
  visibility?: ProductVisibility[];
  billingInterval?: number;
  billingIntervalUnit?: IntervalUnit;
  commitmentInterval?: number;
  commitmentIntervalUnit?: IntervalUnit;
  page?: number;
  pageSize?: number;
}) {
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);

  const client = useClientsStore((store) => store.productsClient);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await client.listProducts({
          type: ProductType.Sku,
          nameIncludes: params.nameIncludes,
          visibility: params.visibility,
          billingInterval: params.billingInterval,
          billingIntervalUnit: params.billingIntervalUnit,
          commitmentInterval: params.commitmentInterval,
          commitmentIntervalUnit: params.commitmentIntervalUnit,
          page: params.page,
          pageSize: params.pageSize,
          returnRelatedAddons: false,
          returnRelatedFeatures: true,
          returnRelatedTiers: true,
        });

        setProducts(data);

        if (data.length === params.pageSize) {
          setPageCount((params.page || 0) + 1);
        }
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    client,
    params.nameIncludes,
    params.visibility,
    params.billingInterval,
    params.billingIntervalUnit,
    params.commitmentInterval,
    params.commitmentIntervalUnit,
    params.page,
    params.pageSize,
  ]);

  return { products, setProducts, isLoading, pageCount };
}
