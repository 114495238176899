import { useState, useEffect } from "react";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";
import { SkyBox } from "@omi-lab/atlas-typescript";

export function useGetSkybox(id?: string) {
  const [skyBox, setSkyBox] = useState<SkyBox | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const [skyBoxsClient] = useClientsStore((store) => [store.skyBoxesClient]);

  useEffect(() => {
    (async () => {
      if (!id) {
        return;
      }
      setIsLoading(true);
      try {
        const skyBox = (
          await skyBoxsClient.getSkyBox({
            skyBoxId: id || "",
          })
        ).data;

        setSkyBox(skyBox);
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id, skyBoxsClient]);

  return { skyBox, isLoading };
}
