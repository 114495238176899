import { useState, useEffect } from "react";
import { Quote, QuoteStatus } from "@omi-lab/cresus-typescript";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";

export function useListQuotes(params: {
  organizationId?: string;
  status?: QuoteStatus;
  isExpired?: boolean;
  page?: number;
  pageSize?: number;
}) {
  const [quotes, setQuotes] = useState<Quote[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [quotesClient] = useClientsStore((store) => [store.quotesClient]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        setQuotes(
          (
            await quotesClient.listQuotes({
              organizationId: params.organizationId,
              status: params.status,
              isExpired: params.isExpired,
              page: params.page,
              pageSize: params.pageSize,
              returnRelatedCoupon: true,
              returnRelatedProductInstance: true,
            })
          ).data,
        );
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    params.organizationId,
    quotesClient,
    params.page,
    params.pageSize,
    params.status,
    params.isExpired,
  ]);

  return { quotes, setQuotes, isLoading };
}
