import { FC } from "react";
import { Form, Input, Col, Select, Space, Button, Divider } from "antd";
import { useFormikContext } from "formik";
import {
  IntervalUnit,
  PlanFeatureName,
  CreateProductBody,
  ProductType,
  ProductVisibility,
} from "@omi-lab/cresus-typescript";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useListCoupons } from "../../Organizations/hooks/useListCoupons";

interface Props {}

export const Details: FC<Props> = () => {
  const { coupons, isLoading: isLoadingCoupons } = useListCoupons({
    page: 1,
    pageSize: 50,
  });

  const {
    values,
    handleChange,
    setValues,
    errors,
    touched,
    handleBlur,
    setTouched,
  } = useFormikContext<CreateProductBody>();

  return (
    <Col
      style={{
        backgroundColor: "#fafafa",
        border: "1px dashed #d9d9d9",
        alignItems: "center",
        padding: "30px",
      }}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        initialValues={values}
      >
        <Form.Item
          label="Name"
          name="name"
          help={touched.name && errors.name}
          validateStatus={touched.name && errors.name ? "error" : "success"}
        >
          <Input
            defaultValue={values.name}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Item>
        <Form.Item
          label="Slug"
          name="slug"
          help={touched.slug && errors.slug}
          validateStatus={touched.slug && errors.slug ? "error" : "success"}
        >
          <Input
            disabled
            defaultValue={values.slug}
            value={values.slug}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          help={touched.type && errors.type}
          validateStatus={touched.type && errors.type ? "error" : "success"}
        >
          <Select
            disabled
            value={values.type}
            defaultValue={values.type}
            onChange={(value) =>
              setValues({
                ...values,
                type: value,
              })
            }
            onBlur={() =>
              setTouched({
                ...touched,
                type: true,
              })
            }
          >
            {Object.values(ProductType).map((value) => (
              <Select.Option value={value}>{value}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Visibility"
          name="visibility"
          help={touched.visibility && errors.visibility}
          validateStatus={
            touched.visibility && errors.visibility ? "error" : "success"
          }
        >
          <Select
            value={values.visibility}
            defaultValue={values.visibility}
            onChange={(value) =>
              setValues({
                ...values,
                visibility: value,
              })
            }
            onBlur={() =>
              setTouched({
                ...touched,
                visibility: true,
              })
            }
          >
            {Object.values(ProductVisibility).map((value) => (
              <Select.Option value={value}>{value}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Billing interval">
          <Space>
            <Form.Item
              name="billingInterval"
              help={touched.billingInterval && errors.billingInterval}
              validateStatus={
                touched.billingInterval && errors.billingInterval
                  ? "error"
                  : "success"
              }
            >
              <Input
                type="number"
                defaultValue={values.billingInterval}
                value={values.billingInterval}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
            <Form.Item
              name="billingIntervalUnit"
              help={touched.billingIntervalUnit && errors.billingIntervalUnit}
              validateStatus={
                touched.billingIntervalUnit && errors.billingIntervalUnit
                  ? "error"
                  : "success"
              }
            >
              <Select
                value={values.billingIntervalUnit!}
                defaultValue={values.billingIntervalUnit!}
                onChange={(value) =>
                  setValues({
                    ...values,
                    billingIntervalUnit: value,
                  })
                }
                onBlur={() =>
                  setTouched({
                    ...touched,
                    billingIntervalUnit: true,
                  })
                }
              >
                {Object.values(IntervalUnit).map((value) => (
                  <Select.Option value={value}>{value}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label="Commitment interval">
          <Space>
            <Form.Item
              name="commitmentInterval"
              help={touched.commitmentInterval && errors.commitmentInterval}
              validateStatus={
                touched.commitmentInterval && errors.commitmentInterval
                  ? "error"
                  : "success"
              }
            >
              <Input
                type="number"
                defaultValue={values.commitmentInterval!}
                value={values.commitmentInterval!}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Item>
            <Form.Item
              name="commitmentIntervalUnit"
              help={
                touched.commitmentIntervalUnit && errors.commitmentIntervalUnit
              }
              validateStatus={
                touched.commitmentIntervalUnit && errors.commitmentIntervalUnit
                  ? "error"
                  : "success"
              }
            >
              <Select
                value={values.commitmentIntervalUnit!}
                defaultValue={values.commitmentIntervalUnit!}
                onChange={(value) =>
                  setValues({
                    ...values,
                    commitmentIntervalUnit: value,
                  })
                }
                onBlur={() =>
                  setTouched({
                    ...touched,
                    commitmentIntervalUnit: true,
                  })
                }
              >
                {Object.values(IntervalUnit).map((value) => (
                  <Select.Option value={value}>{value}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item
          label="Features"
          name="features"
          help={touched.features && errors.features}
          validateStatus={
            touched.features && errors.features ? "error" : "success"
          }
        >
          <Select
            mode="tags"
            onSelect={(name: PlanFeatureName) =>
              setValues({
                ...values,
                features: [...(values.features || []), name],
              })
            }
            onDeselect={(name: PlanFeatureName) => {
              setValues({
                ...values,
                features: (values.features || []).filter(
                  (feature: string) => feature !== name,
                ),
              });
            }}
            onBlur={handleBlur}
          >
            {Object.values(PlanFeatureName).map((feature) => (
              <Select.Option key={feature} value={feature}>
                {feature}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Coupon">
          <Select
            value={values.defaultCouponId || undefined}
            allowClear
            onClear={() => {
              setValues((values) => ({
                ...values,
                couponId: undefined,
              }));
            }}
            loading={isLoadingCoupons}
            onChange={(option) => {
              setValues((values) => ({
                ...values,
                defaultCouponId: option as string,
              }));
            }}
          >
            {coupons.map((coupon) => (
              <Select.Option value={coupon.id} key={coupon.id}>
                {coupon.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Divider />
        {values.tiers?.map((tier, index) => (
          <Form.Item label={`Tier #${index}`}>
            <Space>
              <Form.Item
                label="Up to"
                name={`tiers.${index}.upTo`}
                help={touched.commitmentInterval && errors.commitmentInterval}
                validateStatus={
                  touched.commitmentInterval && errors.commitmentInterval
                    ? "error"
                    : "success"
                }
              >
                <Input
                  type="number"
                  defaultValue={tier.upTo!}
                  value={tier.upTo!}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Item>
              <Form.Item
                label="Unit amount in cents"
                name={`tiers.${index}.unitAmount`}
                help={touched.commitmentInterval && errors.commitmentInterval}
                validateStatus={
                  touched.commitmentInterval && errors.commitmentInterval
                    ? "error"
                    : "success"
                }
              >
                <Input
                  type="number"
                  defaultValue={tier.unitAmount!}
                  value={tier.unitAmount!}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Form.Item>
              <MinusCircleOutlined
                onClick={() =>
                  setValues({
                    ...values,
                    tiers: (values.tiers || []).filter(
                      (_, idx) => idx !== index,
                    ),
                  })
                }
              />
              <Divider />
            </Space>
          </Form.Item>
        ))}
        <Button
          type="dashed"
          onClick={() =>
            setValues({
              ...values,
              tiers: [
                ...(values.tiers || []),
                {
                  upTo: undefined,
                  unitAmount: 100,
                },
              ],
            })
          }
          block
          icon={<PlusOutlined />}
        >
          Add a new tier
        </Button>
      </Form>
    </Col>
  );
};
