import { useState, useEffect } from "react";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";
import { SystemAdministrator } from "@omi-lab/ceos-typescript";

export function useListSystemAdministrators() {
  const [administrators, setAdministrators] = useState<SystemAdministrator[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);

  const [systemAdministratorsClient] = useClientsStore((store) => [
    store.systemAdministratorsClient,
  ]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        await systemAdministratorsClient
          .listSystemAdministrators({
            returnRelatedAccount: true,
            pageSize: 100,
            page: 1,
          })
          .then((res) => setAdministrators(res.data));
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [systemAdministratorsClient]);

  return { administrators, setAdministrators, isLoading };
}
