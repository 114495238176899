import * as Yup from "yup";
import { ProductVisibility, ProductType } from "@omi-lab/cresus-typescript";

export const ValidationScheme = Yup.object()
  .shape({
    name: Yup.string()
      .min(3, "Name should be at least 3 characters long")
      .required("A name is required"),
    slug: Yup.string()
      .min(3, "Slug should be at least 3 characters long")
      .required("A slug is required"),
    visibility: Yup.string()
      .oneOf(Object.values(ProductVisibility))
      .required("A visibility is required"),
    type: Yup.string()
      .oneOf(Object.values(ProductType))
      .required("A type is required"),
    features: Yup.array(Yup.string()).required(
      "A list of features is required.",
    ),
    unitAmount: Yup.number(),
  })
  .required();
