import { StateCreator, State } from "zustand";
import { devtools } from "zustand/middleware";

/**
 * @function instantiateStore
 * @description instantiate the Zustand store
 *
 * @param config classic Zustand config to create a store
 */
const instantiateStore =
  <T extends State>(
    config: StateCreator<T, (fn: (state: T) => void) => void>,
  ): StateCreator<T> =>
  (set, get, api) =>
    config((fn) => set(fn as (state: T) => T), get, api);

/**
 *
 * @function instantiateStoreWithDevtool
 * @description instantiate the Zustand store with ReduxDevtool
 *
 * @param config classic Zustand config to create a store
 * @param storename to give a name of the store in the reduxDevtool
 */
const instantiateStoreWithDevtool = <T extends State>(
  config: StateCreator<T, (fn: (state: T) => void) => void>,
  storename?: string,
): StateCreator<T> => devtools(instantiateStore(config), storename);

/**
 *
 * @function store
 * @description create the store with a devtool access
 * only when the env is in development
 *
 * @param config classic Zustand config to create a store
 * @param storename to give a name of the store in the reduxDevtool
 *
 * TODO: maybe find a way to reduce to make
 * ```
 * store<State>(config)
 * ```
 *
 * rather than
 *
 * ```
 * create<State>(
 * store(config))
 * ```
 */
export const store = <T extends State>(
  config: StateCreator<T, (fn: (state: T) => void) => void>,
  storename?: string,
): StateCreator<T> => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
    return instantiateStoreWithDevtool(config, storename!);
  else return instantiateStore(config);
};
