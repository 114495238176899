import { List, Modal, Pagination } from "antd";
import { FC, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  ModelObject,
  ObjectCollection,
  ObjectOrderBy,
  ObjectTag,
  UpdateObjectBody,
} from "@omi-lab/atlas-typescript";

import { useClientsStore } from "../../../store/clients";
import { ObjectCard } from "./ObjectCard";

interface Props extends RouteComponentProps<any> {
  filter: string;
  organizationId: string | undefined;
  visibility: string[];
  orderBy?: ObjectOrderBy;
  tag?: string[];
  collectionFilter?: string;
  collections: ObjectCollection[];
  tags: ObjectTag[];
  isArchived: boolean;
}

export const ObjectList: FC<Props> = ({
  filter,
  organizationId,
  tag,
  visibility,
  orderBy,
  collectionFilter,
  collections,
  tags,
  isArchived,
}) => {
  const [objects, setObjects] = useState<ModelObject[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  const objectsClient = useClientsStore((state) => state.objectsClient);

  useEffect(() => {
    const getObjects = async () =>
      objectsClient
        .listObjects({
          returnRelatedCollections: true,
          returnRelatedTags: true,
          visibilityIn: organizationId ? ["private"] : visibility,
          nameIncludes: filter,
          orderBy,
          organizationId,
          collectionPath: collectionFilter,
          isArchived,
          tag,
          page,
          pageSize,
        })
        .then((response) => {
          if (response.data.length >= pageSize) {
            setPageCount(page + 1);
          }
          setObjects(response.data);
        });

    getObjects();
  }, [
    objectsClient,
    setObjects,
    page,
    pageSize,
    filter,
    tag,
    organizationId,
    collectionFilter,
    visibility,
    orderBy,
    isArchived,
  ]);

  const deleteObject = async (id: string) => {
    try {
      await objectsClient.deleteObject({
        objectId: id,
      });

      setObjects(objects.filter((object) => object.id !== id));

      Modal.success({
        title: "Success",
        content: "The object was successfully deleted",
      });
    } catch (error: any) {
      Modal.error({
        title: "An error occured while deleting the object",
        content: `${error}`,
      });
    }
  };

  const updateObject = async (id: string, body: UpdateObjectBody) => {
    try {
      const response = await objectsClient.updateObject({
        objectId: id,
        body,
        returnRelatedCollections: true,
        returnRelatedTags: true,
      });

      setObjects(
        objects
          .map((object) => (object.id === id ? response.data : object))
          .filter((object) => object.isArchived === isArchived),
      );
    } catch (error: any) {
      Modal.error({
        title: "An error occured while updating the object",
        content: `${error}`,
      });
    }
  };

  return (
    <>
      <List
        grid={{
          gutter: 4,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
          xxl: 9,
        }}
        dataSource={objects}
        renderItem={(object) => (
          <List.Item>
            <ObjectCard
              key={object.id}
              object={object}
              deleteObject={() => deleteObject(object.id!)}
              updateObject={updateObject}
              collections={collections}
              tags={tags}
            />
          </List.Item>
        )}
      />
      <Pagination
        style={{ marginTop: 20 }}
        current={page}
        total={pageCount * pageSize}
        onChange={setPage}
        onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
        showSizeChanger={true}
      />
    </>
  );
};
