import { FC, useState } from "react";

import { useClientsStore } from "../../../store/clients";
import { CollectionTree } from "../../../components/CollectionTree";
import {
  Collection,
  CreateCollectionBody,
  UpdateCollectionBody,
} from "../../../components/CollectionTree/types";
import { SceneTemplateCollectionsOrderBy } from "@omi-lab/atlas-typescript";
import { Flex } from "antd";
import { CollectionTreeV2 } from "src/components/CollectionTreeV2/container/CollectionTreeV2";
import { DataNode } from "rc-tree/lib/interface";
import { OrganizationSearch } from "src/pkgs/TemplateCustomPreviews/components/OrganizationSearch";

interface Props {}

export const TemplateCollections: FC<Props> = () => {
  const [sceneTemplateCollectionsClient, filesClient] = useClientsStore(
    (store) => [store.sceneTemplateCollectionsClient, store.filesClient],
  );

  const [sceneTemplatesDataNode, setSceneTemplatesDataNode] = useState<
    DataNode[]
  >([]);
  const [currentCollection, setCurrentCollection] = useState<Collection>();
  const [organizationId, setOrganizationId] = useState<string>();

  const createCollection = async (
    collection: CreateCollectionBody,
  ): Promise<Collection> => {
    const file = await filesClient
      .uploadFile({
        file: collection.thumbnail!,
        name: collection.thumbnail!.name,
      })
      .then((response) => response.data);

    return await sceneTemplateCollectionsClient
      .createSceneTemplateCollection({
        body: {
          name: collection.name!,
          path: collection.path!,
          thumbnailFileId: file.id,
          visibility: collection.visibility,
        },
      })
      .then((response) => response.data as Collection);
  };

  const updateCollection = async (
    path: string,
    collection: UpdateCollectionBody,
  ): Promise<Collection> => {
    if (collection.thumbnail) {
      const file = await filesClient
        .uploadFile({
          file: collection.thumbnail!,
          name: collection.thumbnail!.name,
        })
        .then((response) => response.data);

      return await sceneTemplateCollectionsClient
        .updateSceneTemplateCollection({
          sceneTemplateCollectionPath: path,

          body: {
            isHighlighted: collection.isHighlighted,
            weight: collection.weight,
            name: collection.name!,
            thumbnailFileId: file.id,
            visibility: collection.visibility,
          },
        })
        .then((response) => response.data as Collection);
    } else {
      return await sceneTemplateCollectionsClient
        .updateSceneTemplateCollection({
          sceneTemplateCollectionPath: path,

          body: {
            isHighlighted: collection.isHighlighted,
            weight: collection.weight,
            name: collection.name!,
            visibility: collection.visibility,
          },
        })
        .then((response) => response.data as Collection);
    }
  };

  const deleteCollection = async (path: string) => {
    await sceneTemplateCollectionsClient
      .deleteSceneTemplateCollection({
        sceneTemplateCollectionPath: path,
      })
      .then((response) => response.data);
  };

  const getSceneTemplates = async (collectionPath: string) => {
    const response =
      await sceneTemplateCollectionsClient.getSceneTemplateCollection({
        sceneTemplateCollectionPath: collectionPath,
        templateLoadCount: 200,
        returnRelatedTemplates: true,
        returnRelatedCollections: true,
      });

    const sceneTemplates = response.data.templates;

    const newDataNode: DataNode[] = sceneTemplates!.map((sceneTemplate) => {
      return {
        title: sceneTemplate.name,
        key: sceneTemplate.id,
      };
    });

    setSceneTemplatesDataNode([...sceneTemplatesDataNode, ...newDataNode]);
  };

  const handleUpdateDataTree = async (dataTree: DataNode[]) => {
    if (!currentCollection?.path) return;

    await sceneTemplateCollectionsClient.updateSceneTemplateCollection({
      sceneTemplateCollectionPath: currentCollection?.path,
      body: {
        isHighlighted: currentCollection.isHighlighted,
        weight: currentCollection.weight,
        name: currentCollection.name!,
        visibility: currentCollection.visibility,
        templates: dataTree.map((dataNode, index) => {
          return {
            sceneTemplateId: dataNode.key.toString(),
            weight: dataTree.length - index,
          };
        }),
      },
    });
  };

  return (
    <Flex vertical gap={3}>
      <div style={{ padding: "15px" }}>
        <OrganizationSearch
          onChange={(orgId) => {
            setCurrentCollection(undefined);
            setOrganizationId(orgId);
          }}
          onClear={() => {
            setOrganizationId(undefined);
          }}
        />
      </div>
      <Flex>
        <CollectionTree
          title="Scene collections"
          key={organizationId}
          subTitle="Click on a collection to create a new child"
          listCollections={(page, pageSize) =>
            sceneTemplateCollectionsClient
              .listSceneTemplateCollectionsV2({
                page,
                pageSize,
                templateLoadCount: 200,
                organizationId,
                visibility: organizationId ? "private" : undefined,
                orderBy: [SceneTemplateCollectionsOrderBy.WeightAsc],
              })
              .then((response) => response.data.data as Collection[])
          }
          onClickItem={(collection) => {
            setCurrentCollection(collection);
            getSceneTemplates(collection.path);
          }}
          createCollection={createCollection}
          deleteCollection={deleteCollection}
          updateCollection={updateCollection}
        />
        {currentCollection ? (
          <CollectionTreeV2
            title="Template collections"
            subtitle={currentCollection?.path || "Select a collection"}
            onUpdateDataTree={handleUpdateDataTree}
            defaultDataTree={sceneTemplatesDataNode}
          />
        ) : null}
      </Flex>
    </Flex>
  );
};
