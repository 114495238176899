import { Input, Checkbox, Row } from "antd";
import { FC, useEffect, useState } from "react";
import {
  Product,
  ProductType,
  CreateSubscriptionProductInstanceAddonBody,
  CreateSubscriptionProductInstanceBody,
} from "@omi-lab/cresus-typescript";

interface Props {
  addon: Product;
  product?: CreateSubscriptionProductInstanceBody;
  instance?: CreateSubscriptionProductInstanceAddonBody;
  add: (params: { unitCount?: number; targets?: string[] }) => void;
  remove: () => void;
  update: (params: { unitCount?: number; targets?: string[] }) => void;
}

export const OrganizationSubscriptionEditAddon: FC<Props> = ({
  addon,
  product,
  instance,
  add,
  remove,
  update,
}) => {
  const [unitCount, setUnitCount] = useState(instance?.unitCount || undefined);

  useEffect(() => {
    if (
      addon.type === ProductType.Sku ||
      addon.type === ProductType.SkuAddon ||
      addon.type === ProductType.SkuAddonTiered
    ) {
      setUnitCount(product?.unitCount || undefined);
    }
  }, [product?.unitCount, addon.type]);

  useEffect(() => {
    if (addon.type === ProductType.SkuAddonFixed) {
      setUnitCount(1);
    }
    if (addon.type === ProductType.SkuAddonUnits) {
      setUnitCount(0);
    }
  }, [addon.type]);

  return (
    <Row>
      <Checkbox
        checked={!!instance}
        onChange={(e) =>
          e.target.checked ? add({ unitCount: unitCount }) : remove()
        }
      >
        {addon.name} ({addon.id} - {addon.type})
      </Checkbox>
      {addon.type === ProductType.Sku ||
      addon.type === ProductType.SkuAddon ||
      addon.type === ProductType.SkuAddonTiered ? (
        <Input type="number" disabled value={unitCount} />
      ) : undefined}
      {addon.type === ProductType.SkuAddonFixed ? (
        <Input type="number" disabled value={unitCount} />
      ) : undefined}
      {addon.type === ProductType.SkuAddonUnits ? (
        <Input
          type="number"
          value={instance?.unitCount || 0}
          onChange={(e) => {
            setUnitCount(e.target.valueAsNumber);
            update({ unitCount: e.target.valueAsNumber });
          }}
        />
      ) : undefined}
    </Row>
  );
};
