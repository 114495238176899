import { FC } from "react";
import { Input, Form, notification, Modal } from "antd";
import { ObjectVisibility, CreateProductBody } from "@omi-lab/atlas-typescript";

import { ObjectSearch } from "../../Billing/components/ObjectSearch";
import { useFormik } from "formik";

interface Props {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  organizationId: string;
  createProduct: (body: CreateProductBody) => Promise<void>;
}

export const OrganizationProductAdd: FC<Props> = (props) => {
  const { values, setValues, submitForm } = useFormik({
    initialValues: {
      name: "",
      organizationId: props.organizationId,
      objectIds: [],
    } as CreateProductBody,
    onSubmit: async (_, { resetForm }) => {
      try {
        await props.createProduct(values);
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error.message,
        });
      } finally {
        resetForm();
        props.setIsVisible(false);
      }
    },
  });

  return (
    <Modal
      width="50%"
      open={props.isVisible}
      okText={"Add product"}
      onCancel={() => props.setIsVisible(false)}
      onOk={submitForm}
    >
      <Form>
        <Form.Item label="Name">
          <Input
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
          />
        </Form.Item>
        <Form.Item label="Objects">
          <ObjectSearch
            visibilityIn={[ObjectVisibility.Private]}
            orgId={props.organizationId}
            onClear={() => setValues({ ...values, objectIds: [] })}
            onDeselect={(id) =>
              setValues({
                ...values,
                objectIds: values.objectIds?.filter((i) => i !== id),
              })
            }
            onSelect={(id) =>
              setValues({
                ...values,
                objectIds: [...(values.objectIds || []), id],
              })
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
