import {
  SystemAdministrator,
  SystemAdministratorPermission,
} from "@omi-lab/ceos-typescript";
import { Modal, Input, Select, Form } from "antd";
import { useFormik } from "formik";
import { FC } from "react";

interface Props {
  administrator: SystemAdministrator;
  isOpen: boolean;
  onOk(administrator: SystemAdministrator): void;
  onCancel(): void;
}

export const UpdateSystemAdministratorModal: FC<Props> = ({
  onOk,
  onCancel,
  isOpen,
  administrator,
}) => {
  const { values, setValues } = useFormik({
    initialValues: administrator,
    onSubmit: () => {},
  });

  return (
    <Modal
      title="Update system administrator"
      open={isOpen}
      onOk={() => onOk(values)}
      onCancel={onCancel}
    >
      <Form
        initialValues={{
          firstName: values.account?.firstName,
          lastName: values.account?.lastName,
          permissions: values.permissions,
        }}
      >
        <Form.Item label="First name" name="firstName">
          <Input
            placeholder="First name"
            name="name"
            disabled={true}
            value={administrator.account?.firstName}
          />
        </Form.Item>
        <Form.Item label="Last name" name="lastName">
          <Input
            placeholder="Last name"
            name="name"
            disabled={true}
            value={administrator.account?.lastName}
          />
        </Form.Item>
        <Form.Item
          label="Permissions"
          name="permissions"
          initialValue={values.permissions}
        >
          <Select
            mode="tags"
            placeholder="Select permissions"
            style={{ width: "100%" }}
            showArrow={true}
            maxTagCount={1}
            value={values.permissions}
            onSelect={(key) =>
              setValues((values) => ({
                ...values,
                permissions: [...values.permissions, key],
              }))
            }
            onDeselect={(key) =>
              setValues((values) => ({
                ...values,
                permissions: values.permissions.filter(
                  (permission) => permission !== key,
                ),
              }))
            }
          >
            {Object.values(SystemAdministratorPermission).map((permission) => (
              <Select.Option key={permission} value={permission}>
                {permission}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
