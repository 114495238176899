import { useState, useEffect } from "react";
import { Coupon } from "@omi-lab/cresus-typescript";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";

export function useListCoupons(params: {
  nameIncludes?: string;
  page?: number;
  pageSize?: number;
}) {
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);

  const [couponsClient] = useClientsStore((store) => [store.couponsClient]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await couponsClient.listCoupons({
          nameIncludes: params.nameIncludes,
          page: params.page,
          pageSize: params.pageSize,
        });

        setCoupons(data);

        if (data.length === params.pageSize) {
          setPageCount((params.page || 0) + 1);
        }
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [couponsClient, params.nameIncludes, params.page, params.pageSize]);

  return { coupons, setCoupons, isLoading, pageCount };
}
