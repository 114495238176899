import { Table, Col, Select, notification, Button, Row } from "antd";
import { FC, useState } from "react";
import {
  PlanFeatureName,
  PlanVisibility,
  UpdateProductBody,
  Product,
  CreateProductAddonBody,
  ProductType,
} from "@omi-lab/cresus-typescript";

import { useClientsStore } from "../../../store/clients";

import { useListProductAddons } from "../hooks";
import { CreateAddonModal } from "../components/CreateAddonModal";

interface Props {
  product: Product;
}

export const Addons: FC<Props> = (props) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const client = useClientsStore((store) => store.productsClient);

  const { addons, setAddons, isLoading } = useListProductAddons({
    productId: props.product.id,
  });

  const updateAddon = async (id: string, body: UpdateProductBody) => {
    try {
      setIsUpdating(true);
      await client
        .updateProduct({
          productId: id,
          body,
          returnRelatedAddons: true,
          returnRelatedFeatures: true,
          returnRelatedTiers: true,
        })
        .then(({ data }) =>
          setAddons(addons.map((addon) => (addon.id === id ? data : addon))),
        );
      notification.success({
        message: "The addon was successfully updated.",
        duration: 1,
      });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || error.message,
        duration: 1,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  const createAddon = async (id: string, body: CreateProductAddonBody) => {
    try {
      setIsUpdating(true);
      await client
        .createProductAddon({
          productId: id,
          body: {
            ...body,
            type:
              body.tiers && body.tiers.length > 0
                ? ProductType.SkuAddonTiered
                : body.type,
            tiers: body.tiers && body.tiers.length > 0 ? body.tiers : undefined,
            unitAmount:
              !body.tiers || body.tiers.length === 0
                ? body.unitAmount
                : undefined,
          },
          returnRelatedFeatures: true,
          returnRelatedTiers: true,
        })
        .then(({ data }) => setAddons([...addons, data]));
      notification.success({
        message: "The addon was successfully created.",
        duration: 1,
      });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || error.message,
        duration: 1,
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Col style={{ padding: "15px" }}>
      <Row gutter={16} justify="space-between" style={{ marginBottom: "10px" }}>
        <Col>
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            +
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={addons}
        pagination={false}
        loading={isLoading}
        columns={[
          {
            title: "ID",
            key: "id",
            dataIndex: "id",
            width: "15%",
            render: (_, record) => <p>{record.id}</p>,
          },
          {
            title: "Name",
            key: "name",
            dataIndex: "name",
            width: "10%",
            render: (_, record) => <p>{record.name}</p>,
          },
          {
            title: "Slug",
            key: "slug",
            dataIndex: "slug",
            width: "10%",
            render: (_, record) => <p>{record.slug}</p>,
          },
          {
            title: "Type",
            key: "type",
            dataIndex: "type",
            width: "10%",
          },
          {
            title: "Billing",
            key: "billing",
            width: "15%",
            render: (_, __: Product) => (
              <p>
                Every {props.product.billingInterval}{" "}
                {props.product.billingIntervalUnit}
              </p>
            ),
          },
          {
            title: "Price",
            key: "price",
            width: "15%",
            render: (_, record: Product) =>
              record.tiers?.length ? (
                <p>
                  From {record.tiers![0].unitAmount / 100} to{" "}
                  {record.tiers![record.tiers!.length - 1].unitAmount / 100} per
                  SKU
                </p>
              ) : (
                <p>{record.unitAmount! / 100} per SKU</p>
              ),
          },
          {
            title: "Visibility",
            key: "visibility",
            width: "15%",
            render: (_, record: Product) => (
              <Select
                value={record.visibility}
                onChange={(v) =>
                  updateAddon(record.id, {
                    visibility: v,
                  })
                }
              >
                {Object.values(PlanVisibility).map((visibility) => (
                  <Select.Option value={visibility}>{visibility}</Select.Option>
                ))}
              </Select>
            ),
          },
          {
            title: "Features",
            key: "features",
            width: "10%",
            render: (_, record: Product) => (
              <Select
                style={{ minWidth: "200px" }}
                disabled={isUpdating}
                mode="multiple"
                value={(record.features || []).map(({ name }) => name)}
                maxTagCount={3}
                onSelect={(name) =>
                  updateAddon(record.id, {
                    features: (record.features || [])
                      .map((feature) => feature.name)
                      .concat(name),
                  })
                }
                onDeselect={(name) =>
                  updateAddon(record.id, {
                    features: (record.features || [])
                      .filter((feature) => feature.name !== name)
                      .map((feature) => feature.name),
                  })
                }
              >
                {Object.values(PlanFeatureName).map((name) => (
                  <Select.Option value={name} key={name}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            ),
          },
        ]}
      />
      <CreateAddonModal
        isOpen={isModalVisible}
        close={() => setIsModalVisible(false)}
        createAddon={createAddon}
        productId={props.product.id}
        key={props.product.id}
      />
    </Col>
  );
};
