import { Col, Table, notification, Button } from "antd";
import { FC, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import { useClientsStore } from "../../../store/clients";

import { AddProductVerticalModal } from "../components/AddProductVerticalModal";
import { useListProductVerticals } from "../hooks";
import { CreateProductVerticalBody } from "@omi-lab/ceos-typescript";

interface Props extends RouteComponentProps<any> {}

export const OrganizationVerticals: FC<Props> = () => {
  const client = useClientsStore((state) => state.productVerticalsClient);

  const { verticals, setVerticals, isLoading } = useListProductVerticals();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const createProductVertical = async (body: CreateProductVerticalBody) => {
    try {
      const { data } = await client.createProductVertical({ body });

      setVerticals([...verticals, data]);

      setIsModalVisible(false);
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || error.message,
      });
    }
  };

  const deleteProductVertical = async (name: string) => {
    try {
      await client.deleteProductVertical({ productVerticalName: name });

      setVerticals(verticals.filter((vertical) => vertical.name !== name));
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || error.message,
      });
    }
  };

  return (
    <Col style={{ padding: "15px" }}>
      <Table
        loading={isLoading}
        dataSource={verticals}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Slug",
            dataIndex: "slug",
            key: "slug",
          },
          {
            title: "Actions",
            key: "actions",
            render: (_, record) => (
              <Button danger onClick={() => deleteProductVertical(record.name)}>
                Delete
              </Button>
            ),
          },
        ]}
      />
      <Button onClick={() => setIsModalVisible(true)}>Add</Button>
      <AddProductVerticalModal
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        createProductVertical={createProductVertical}
      />
    </Col>
  );
};
