import { useState, useEffect } from "react";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";

export function useGetProductCount(params: { organizationId?: string }) {
  const [productCount, setProductCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const [productsClient] = useClientsStore((store) => [
    store.atlasProductsClient,
  ]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await productsClient.countProducts({
          organizationId: params.organizationId,
        });

        setProductCount(data);
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [productsClient, params.organizationId]);

  return { productCount, setProductCount, isLoading };
}
