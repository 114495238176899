import { LightSetCollection } from "@omi-lab/atlas-typescript";
import { CollectionTree } from "../../../components/CollectionTree";
import {
  CreateCollectionBody,
  UpdateCollectionBody,
} from "../../../components/CollectionTree/types";
import { FC } from "react";
import { useClientsStore } from "../../../store/clients";

interface Props {}

export const LightSetCollections: FC<Props> = () => {
  const [lightSetCollectionsClient, filesClient] = useClientsStore((store) => [
    store.lightSetCollectionsClient,
    store.filesClient,
  ]);

  const createCollection = async (
    collection: CreateCollectionBody,
  ): Promise<LightSetCollection> => {
    const file = await filesClient
      .uploadFile({
        file: collection.thumbnail!,
        name: collection.thumbnail!.name,
      })
      .then((response) => response.data);

    return await lightSetCollectionsClient
      .createLightSetCollection({
        body: {
          name: collection.name!,
          path: collection.path!,
          thumbnailFileId: file.id,
        },
      })
      .then((response) => response.data);
  };

  const updateCollection = async (
    path: string,
    collection: UpdateCollectionBody,
  ): Promise<LightSetCollection> => {
    const file = await filesClient
      .uploadFile({
        file: collection.thumbnail!,
        name: collection.thumbnail!.name,
      })
      .then((response) => response.data);

    return await lightSetCollectionsClient
      .updateLightSetCollection({
        lightSetCollectionPath: path,
        body: {
          name: collection.name!,
          thumbnailFileId: file.id,
        },
      })
      .then((response) => response.data);
  };

  const deleteCollection = async (path: string) => {
    await lightSetCollectionsClient
      .deleteLightSetCollection({
        lightSetCollectionPath: path,
      })
      .then((response) => response.data);
  };

  return (
    <CollectionTree
      title="Light set collections"
      subTitle="Click on a collection to create a new child"
      listCollections={(page, pageSize) =>
        lightSetCollectionsClient
          .listLightSetCollections({
            page,
            pageSize,
          })
          .then((response) => response.data)
      }
      createCollection={createCollection}
      deleteCollection={deleteCollection}
      updateCollection={updateCollection}
    />
  );
};
