import { useState, useEffect } from "react";
import { TextContent } from "@omi-lab/atlas-typescript";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";

export function useGetTextContents(textContentId?: string) {
  const [textContent, setTextContent] = useState<TextContent>();
  const [isLoading, setIsLoading] = useState(false);

  const client = useClientsStore((store) => store.textContentsClient);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (!textContentId) return;
        const { data } = await client.getTextContent({
          returnRelatedTranslations: true,
          textContentId,
        });

        setTextContent(data);
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [client, textContentId]);

  return { textContent, setTextContent, isLoading };
}
