import { Spin, List, Input, Col, Select, Row, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  SceneTemplateCollection as ISceneTemplateCollection,
  ListSceneTemplatesV2OrderByEnum,
  SceneTemplateTag,
} from "@omi-lab/atlas-typescript";

import { useClientsStore } from "../../../store/clients";

import { TemplateList } from "../components/TemplateList";

function getCollectionLeaves(
  collection: ISceneTemplateCollection,
): ISceneTemplateCollection[] {
  function traverse(
    acc: ISceneTemplateCollection[],
    node: ISceneTemplateCollection,
  ): ISceneTemplateCollection[] {
    if (node.collections?.length) return node.collections.reduce(traverse, acc);
    acc.push(node);
    return acc;
  }

  return traverse([], collection);
}

type Props = {
  mode?: "select" | "view";
  onSelect?: (id: string) => void;
  selectedTemplatesId?: string[];
};

export const Templates = ({
  mode = "view",
  onSelect,
  selectedTemplatesId,
}: Props) => {
  const [collections, setCollections] = useState<ISceneTemplateCollection[]>(
    [],
  );
  const [tags, setTags] = useState<SceneTemplateTag[]>([]);

  const [filter, setFilter] = useState<string>();
  const [collectionFilter, setCollectionFilter] = useState<string>();
  const [visibilityFilter, setVisibilityFilter] = useState<
    "hidden" | "all" | "public" | "private" | undefined
  >("all");

  const [orderBy, setOrderBy] = useState<ListSceneTemplatesV2OrderByEnum>();

  const [sceneTemplateCollectionsClient, sceneTemplateTagsClient] =
    useClientsStore((state) => [
      state.sceneTemplateCollectionsClient,
      state.sceneTemplateTagsClient,
    ]);

  useEffect(() => {
    const getCollections = async () =>
      sceneTemplateCollectionsClient.listSceneTemplateCollectionsV2({
        pageSize: 100,
      });

    getCollections()
      .then((response) => response.data)
      .then((collections) =>
        collections.data.reduce(
          (acc, next) => [...acc, ...getCollectionLeaves(next)],
          [] as ISceneTemplateCollection[],
        ),
      )
      .then((collections) => setCollections(collections));
  }, [sceneTemplateCollectionsClient, setCollections]);

  useEffect(() => {
    const getTags = async () => sceneTemplateTagsClient.listSceneTemplateTags();

    getTags()
      .then((response) => response.data)
      .then((tags) => setTags(tags));
  }, [sceneTemplateTagsClient, setTags]);

  return (
    <Col style={{ padding: "15px" }}>
      {collections ? (
        <List grid={{ gutter: 16 }}>
          <List.Item>
            <Row gutter={16} justify="space-between">
              <Col span={6}>
                <Input
                  placeholder="Search for an object"
                  prefix={<SearchOutlined />}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </Col>
              <Col span={6}>
                <Form.Item name="collection">
                  <Select
                    placeholder="Collection"
                    onClear={() => setCollectionFilter(undefined)}
                    allowClear
                    onChange={(collection) =>
                      setCollectionFilter(collection as string)
                    }
                  >
                    {collections.map((collection) => (
                      <Select.Option value={collection.path}>
                        {collection.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="visibility">
                  <Select
                    placeholder="Visibility"
                    onClear={() => setVisibilityFilter(undefined)}
                    allowClear
                    onChange={(visibility) =>
                      setVisibilityFilter(
                        visibility as
                          | "hidden"
                          | "all"
                          | "public"
                          | "private"
                          | undefined,
                      )
                    }
                  >
                    {["hidden", "all", "public", "private"].map(
                      (visibility) => (
                        <Select.Option value={visibility}>
                          {visibility}
                        </Select.Option>
                      ),
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="orderBy">
                  <Select
                    placeholder="Order by"
                    onChange={(orderBy) =>
                      setOrderBy(orderBy as ListSceneTemplatesV2OrderByEnum)
                    }
                  >
                    {Object.values(ListSceneTemplatesV2OrderByEnum).map(
                      (orderBy) => (
                        <Select.Option value={orderBy}>{orderBy}</Select.Option>
                      ),
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </List.Item>
          <List.Item>
            <TemplateList
              mode={mode}
              onSelect={onSelect}
              selectedTemplatesId={selectedTemplatesId}
              filter={filter}
              collectionFilter={collectionFilter}
              visibilityFilter={visibilityFilter}
              orderBy={orderBy}
              collections={collections}
              tags={tags}
            />
          </List.Item>
        </List>
      ) : (
        <Spin size="large" />
      )}
    </Col>
  );
};
