import {
  FormatPreset,
  FormatPresetSlug,
  FormatPresetSlugsApiUpdateFormatPresetSlugV2Request,
  Language,
  ListFormatPresetsV2ResponseBody,
  Translation,
  UpdateTextContentBody,
} from "@omi-lab/atlas-typescript";

import { Dropzone } from "src/components/Dropzone";

import Compressor from "compressorjs";

import * as Yup from "yup";

import {
  Button,
  Col,
  Flex,
  Form,
  Image,
  Input,
  List,
  Modal,
  Pagination,
  Skeleton,
  Space,
  Spin,
  notification,
} from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useFile } from "src/hooks/useFile";
import { useClientsStore } from "src/store/clients";
import { useGetTextContents } from "./Templates/hooks/useGetTextContents";

type UpdateFormatPresetTranslationModalProps = {
  isOpen: boolean;
  close: () => void;
  textContentId: string;
};

const UpdateFormatPresetTranslationModal = ({
  isOpen,
  close,
  textContentId,
}: UpdateFormatPresetTranslationModalProps) => {
  const client = useClientsStore((state) => state.textContentsClient);
  const [isLoading, setIsLoading] = useState(false);

  const {
    isLoading: textContentLoading,
    setTextContent,
    textContent,
  } = useGetTextContents(textContentId);

  const updateTranslation = async (
    textContentId: string,
    languageId: Language,
    translation: string,
  ) => {
    if (textContent === undefined) return;
    setTextContent({
      ...textContent,
      translations: [
        ...(textContent?.translations?.filter(
          (translation) => translation.languageId !== languageId,
        ) || []),
        {
          languageId,
          translation,
          textContentId,
        } as Translation,
      ],
    });
  };

  const updateTextContent = async (id: string, body: UpdateTextContentBody) => {
    try {
      setIsLoading(true);
      await client.updateTextContent({
        textContentId: id,
        body,
        returnRelatedTranslations: true,
      });

      notification.success({
        message: "The text content was successfully updated.",
        duration: 4,
      });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    updateTextContent(textContentId, {
      translations:
        textContent?.translations?.map((translation) => ({
          languageId: translation.languageId,
          translation: translation.translation,
        })) || [],
    });

    close();
  };

  if (textContentLoading) return <Spin />;

  return (
    <Modal
      title="Update format preset translation"
      open={isOpen}
      onCancel={close}
      onOk={handleSubmit}
      okButtonProps={{ loading: isLoading }}
    >
      <Col style={{ padding: "15px" }}>
        <Form.Item label="French">
          <Input
            placeholder="French"
            type="text"
            value={
              textContent?.translations?.find(
                ({ languageId }) => languageId === Language.Fr,
              )?.translation ?? textContent?.originalText
            }
            onChange={(e) =>
              updateTranslation(textContentId, Language.Fr, e.target.value)
            }
          />
        </Form.Item>

        <Form.Item label="English">
          <Input
            placeholder="English"
            type="text"
            value={
              textContent?.translations?.find(
                ({ languageId }) => languageId === Language.En,
              )?.translation
            }
            onChange={(e) =>
              updateTranslation(textContentId, Language.En, e.target.value)
            }
          />
        </Form.Item>
        <Form.Item label="Spanish">
          <Input
            placeholder="Spanish"
            type="text"
            value={
              textContent?.translations?.find(
                ({ languageId }) => languageId === Language.Es,
              )?.translation
            }
            onChange={(e) =>
              updateTranslation(textContentId, Language.Es, e.target.value)
            }
          />
        </Form.Item>
      </Col>
    </Modal>
  );
};

type UpdateFormatPresetSlugModalProps = {
  isOpen: boolean;
  handleUpdateFormatPreset: (
    body: FormatPresetSlugsApiUpdateFormatPresetSlugV2Request,
  ) => Promise<void>;
  formatPresetSlug: FormatPresetSlug | undefined;
  close: () => void;
};

export const validationUpdateFormatPresetScheme = Yup.object().shape({
  formatPresetSlugName: Yup.string(),
  thumbnailFileId: Yup.string().required("Thumbnail is required"),
});

const UpdateFormatPresetSlugModal = ({
  isOpen,
  close,
  handleUpdateFormatPreset,
  formatPresetSlug,
}: UpdateFormatPresetSlugModalProps) => {
  const filesClient = useClientsStore((state) => state.filesClient);

  const [uploadFile, setUploadFile] = useState<File>();
  const [loading, setLoading] = useState(false);

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleSubmit,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: {
      ...formatPresetSlug,
    },
    onSubmit: async (values) => {
      setLoading(true);

      try {
        if (!formatPresetSlug) return;
        await handleUpdateFormatPreset({
          formatPresetSlugName: formatPresetSlug.name,
          body: {
            ...values,
            thumbnailFileId: values.thumbnailFileId ?? "",
          },
        });
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
      resetForm();
      close();
    },
    validationSchema: validationUpdateFormatPresetScheme,
    validateOnMount: false,
  });

  const handleOnCancel = () => {
    resetForm();
    close();
  };

  const uploadThumbnailFile = async (file: File) => {
    const { data } = await filesClient.uploadFile({
      file,
      name: file.name,
    });

    setValues({
      ...values,
      thumbnailFileId: data.id,
    });
  };

  const handleOnDrop = async (files: File[]) => {
    new Compressor(files[0], {
      maxWidth: 562,
      success: async (compressed: File) => {
        setUploadFile(compressed);
        uploadThumbnailFile(compressed);
      },
      error: (error) => {
        notification.error({
          message: error.message,
        });
      },
    });
  };

  const submitAndClose = () => {
    handleSubmit();
  };

  return (
    <Modal
      title="Update format preset"
      open={isOpen}
      onCancel={handleOnCancel}
      onOk={submitAndClose}
      okButtonProps={{ loading }}
    >
      <Col style={{ padding: "15px" }}>
        <Form initialValues={values}>
          <Form.Item
            label="Name"
            name="name"
            help={touched?.name && errors?.name}
            validateStatus={touched.name && errors.name ? "error" : "success"}
          >
            <Input
              value={values.name ?? ""}
              disabled
              onChange={(e) =>
                setValues({
                  ...values,
                  name: e.target.value,
                })
              }
              onBlur={handleBlur}
            />
          </Form.Item>

          <Form.Item
            label="Thumbnail file"
            name="thumbnailFileId"
            help={touched.thumbnailFileId && errors.thumbnailFileId}
            validateStatus={
              touched.thumbnailFileId && errors.thumbnailFileId
                ? "error"
                : "success"
            }
          >
            <Dropzone
              onDrop={handleOnDrop}
              files={uploadFile ? [uploadFile] : []}
              accept="image/*"
              maxFiles={1}
            />
          </Form.Item>
        </Form>
      </Col>
    </Modal>
  );
};

type FormatPresetImgProps = {
  thumbnailId?: string;
};

const FormatPresetImg = ({ thumbnailId }: FormatPresetImgProps) => {
  const { file, isLoading } = useFile(thumbnailId, {
    returnRelatedCategories: true,
  });

  const url = file?.url ?? undefined;

  const isIdle = !isLoading || !url;

  if (isIdle) {
    return <Skeleton.Image />;
  }

  return <Image height={140} alt="thumbnail" src={url} />;
};

type FormatPresetCardProps = {
  formatPreset: FormatPreset;
};

const FormatPresetCard = ({
  formatPreset: defaultFormatPreset,
}: FormatPresetCardProps) => {
  const [isUpdateFormatPresetModal, setIsUpdateFormatPresetModal] =
    useState(false);

  const { formatPresetSlugsClient } = useClientsStore((state) => state);

  const [formatPreset, setFormatPreset] =
    useState<FormatPreset>(defaultFormatPreset);

  const updateFormatPresetSlug = async (
    body: FormatPresetSlugsApiUpdateFormatPresetSlugV2Request,
  ) => {
    try {
      const { data } = await formatPresetSlugsClient.updateFormatPresetSlugV2({
        ...body,
      });

      setFormatPreset({ ...formatPreset, slug: data });
    } catch (error: any) {
      console.log(error);
    }
  };

  const openUpdateFormatPresetModal = () => setIsUpdateFormatPresetModal(true);

  const closeUpdateFormatPresetModal = () =>
    setIsUpdateFormatPresetModal(false);

  const [
    isUpdateFormatPresetTranslationModal,
    setIsUpdateFormatPresetTranslationModal,
  ] = useState(false);

  const openUpdateFormatPresetTranslationModal = () => {
    setIsUpdateFormatPresetTranslationModal(true);
  };

  const closeUpdateFormatPresetTranslationModal = () => {
    setIsUpdateFormatPresetTranslationModal(false);
  };

  return (
    <>
      <List.Item
        key={formatPreset.name}
        title={formatPreset.name ?? ""}
        actions={[
          <Space>
            <Button onClick={openUpdateFormatPresetTranslationModal}>
              Update translation
            </Button>
            <Button onClick={openUpdateFormatPresetModal}>
              Update format preset
            </Button>
          </Space>,
        ]}
        extra={
          <FormatPresetImg
            thumbnailId={formatPreset.slug?.thumbnailFileId ?? undefined}
          />
        }
      >
        <List.Item.Meta
          title={formatPreset.slug?.name}
          description={
            <div className="flex-col flex">
              <div>{`preset name: ${formatPreset.name ?? "N/A"}`}</div>
              <div>{`size: [${formatPreset.sizePx.join("x")}]`}</div>
              <div>{`id: ${formatPreset.id}`}</div>
            </div>
          }
        />
      </List.Item>
      <UpdateFormatPresetSlugModal
        key={isUpdateFormatPresetModal ? "open" : "close"}
        isOpen={isUpdateFormatPresetModal}
        close={closeUpdateFormatPresetModal}
        handleUpdateFormatPreset={updateFormatPresetSlug}
        formatPresetSlug={formatPreset.slug}
      />
      <UpdateFormatPresetTranslationModal
        key={
          isUpdateFormatPresetTranslationModal
            ? "open-translation"
            : "close-translation"
        }
        isOpen={isUpdateFormatPresetTranslationModal}
        close={closeUpdateFormatPresetTranslationModal}
        textContentId={formatPreset.slug?.nameTextContentId ?? ""}
      />
    </>
  );
};

const PAGE_SIZE = 10;

export const FormatPresets = () => {
  const { formatPresetsClient } = useClientsStore((state) => state);

  const [page, setPage] = useState(1);
  const [formatPresets, setFormatPresets] =
    useState<ListFormatPresetsV2ResponseBody>();

  useEffect(() => {
    const listFormatPresets = async () =>
      formatPresetsClient
        .listFormatPresetsV2({
          page,
          pageSize: PAGE_SIZE,
          returnRelatedSlug: true,
        })
        .then((response) => {
          setFormatPresets(response.data);
        });

    listFormatPresets();
  }, [formatPresetsClient, page]);

  return (
    <>
      <Flex gap="middle" vertical style={{ padding: "1rem" }}>
        <List
          itemLayout="vertical"
          size="large"
          dataSource={formatPresets?.data ?? []}
          key={JSON.stringify(formatPresets)}
          footer={
            <Pagination
              current={page}
              pageSize={PAGE_SIZE}
              total={formatPresets?.count ?? 0}
              onChange={setPage}
            />
          }
          renderItem={(item) => <FormatPresetCard formatPreset={item} />}
        />
      </Flex>
    </>
  );
};

export default FormatPresets;
