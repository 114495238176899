import { useState, useEffect } from "react";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";
import { Render } from "@omi-lab/studio-typescript";

export function useListRenders(params: {
  nameIncludes?: string;
  page?: number;
  pageSize: number;
  blackList?: string[];
  whiteList?: string[];
  dateRange?: string[];
}) {
  const [renders, setRenders] = useState<Render[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);

  const [client] = useClientsStore((store) => [store.renders]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { data } = await client.listRendersV2({
          organizationIdIn: params.whiteList,
          organizationIdNotIn: params.blackList,
          returnRelatedPreview: true,
          returnRelatedRender: true,
          page: params.page,
          pageSize: params.pageSize,
          returnRelatedScene: true,
          createdAfter: params.dateRange?.[0]
            ? new Date(params.dateRange?.[0]).toISOString()
            : undefined,
          createdBefore: params.dateRange?.[1]
            ? new Date(params.dateRange?.[1]).toISOString()
            : undefined,
        });

        setRenders(data.data);

        setPageCount(Math.ceil(data.count / params.pageSize));
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [
    client,
    params.nameIncludes,
    params.page,
    params.pageSize,
    params.blackList,
    params.whiteList,
    params.dateRange,
  ]);

  return { renders, setRenders, isLoading, pageCount };
}
