import {
  SceneTemplateCollection,
  SceneTemplateTag,
  Translation,
  Language,
  UpdateTextContentBody,
} from "@omi-lab/atlas-typescript";
import {
  Spin,
  Modal,
  notification,
  Input,
  Select,
  Button,
  Layout,
  Space,
} from "antd";
import { FC, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useListTemplateTags } from "../hooks/useListTemplateTags";
import { useGetTextContents } from "../hooks/useGetTextContents";
import { useClientsStore } from "../../../store/clients";
import { Templates } from "../container/Templates";
const { Header, Content } = Layout;

const { Option } = Select;

interface Props extends RouteComponentProps<any> {
  filter: string;
  collections: SceneTemplateCollection[];
  tags: SceneTemplateTag[];
}

type TagLineProps = {
  tag: SceneTemplateTag;
};

type AssignTagToTemplatesModalProps = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  tag: SceneTemplateTag;
};

const AssignTagToTemplatesModal = ({
  showModal,
  setShowModal,
  tag,
}: AssignTagToTemplatesModalProps) => {
  const [selectedTemplatesID, setSelectedTemplatesID] = useState<string[]>([]);
  const sceneTemplatesClient = useClientsStore(
    (state) => state.sceneTemplatesClient,
  );
  const [isLoading, setIsLoading] = useState(false);
  const addTemplate = (templateID: string) => {
    let items: string[] = [...selectedTemplatesID];
    if (!selectedTemplatesID.includes(templateID)) {
      items.push(templateID);
    } else {
      items = items.filter((f) => f !== templateID);
    }
    setSelectedTemplatesID(items);
  };

  const updateTemplates = async () => {
    try {
      setIsLoading(true);

      await Promise.all(
        selectedTemplatesID.map(async (e) => {
          try {
            const template = (
              await sceneTemplatesClient.getSceneTemplate({
                sceneTemplateId: e,
              })
            ).data;

            await sceneTemplatesClient.updateSceneTemplate({
              sceneTemplateId: e,
              body: {
                organizationId: template.organizationId,
                visibility: template.visibility,
                tags: [...(template.tags?.map((t) => t.name) || []), tag.name],
              },
            });
          } catch (error: any) {
            notification.error({
              message: error?.response?.data?.error || error.message,
            });
          }
        }),
      );

      notification.success({
        message: "The tag was successfully assigned.",
        duration: 4,
      });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      confirmLoading={isLoading}
      width={"90%"}
      title={tag.name}
      open={showModal}
      onOk={async () => {
        await updateTemplates();
        setShowModal(false);
      }}
      onCancel={() => setShowModal(false)}
    >
      <div style={{ maxHeight: "70vh", overflow: "auto" }}>
        <Templates
          selectedTemplatesId={selectedTemplatesID}
          onSelect={addTemplate}
          mode="select"
        />
      </div>
    </Modal>
  );
};

const TagLine = ({ tag }: TagLineProps) => {
  const {
    isLoading: textContentLoading,
    setTextContent,
    textContent,
  } = useGetTextContents(tag.nameTextContentId);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { textContentsClient, sceneTemplateTagsClient } = useClientsStore(
    (state) => state,
  );

  const updateTranslation = async (
    id: string,
    language: Language,
    translation: string,
  ) => {
    if (textContent === undefined) return;
    setTextContent({
      ...textContent,
      translations: [
        ...(textContent?.translations?.filter(
          (translation) => translation.languageId !== language,
        ) || []),
        {
          languageId: language,
          translation: translation,
          textContentId: id,
        } as Translation,
      ],
    });
  };

  const updateTextContent = async (id: string, body: UpdateTextContentBody) => {
    try {
      setIsLoading(true);
      await textContentsClient.updateTextContent({
        textContentId: id,
        body,
        returnRelatedTranslations: true,
      });

      notification.success({
        message: "The text content was successfully updated.",
        duration: 4,
      });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteTextContent = async () => {
    try {
      setIsLoading(true);
      await sceneTemplateTagsClient.deleteSceneTemplateTag({
        sceneTemplateTagName: tag.originalName,
      });

      notification.success({
        message: "The tag was successfully deleted.",
        duration: 4,
      });
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.error || error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (textContentLoading) return <Spin />;

  return (
    <span
      style={{
        width: "100%",
        display: "flex",
        gap: "10px",
        padding: "10px",
        borderBottom: "1px solid lightgrey",
        alignItems: "center",
        background: "white",
      }}
    >
      <div style={{ minWidth: 200, flex: "1", display: "flex" }}>
        {tag.name}
      </div>

      {textContent ? (
        <>
          <Input
            placeholder="French"
            type="text"
            value={
              textContent?.translations?.find(
                ({ languageId }) => languageId === Language.Fr,
              )?.translation || tag.name
            }
            onChange={(e) =>
              updateTranslation(textContent.id, Language.Fr, e.target.value)
            }
          />
          <Input
            placeholder="English"
            type="text"
            value={
              textContent?.translations?.find(
                ({ languageId }) => languageId === Language.En,
              )?.translation
            }
            onChange={(e) =>
              updateTranslation(textContent.id, Language.En, e.target.value)
            }
          />
          <Input
            placeholder="Spanish"
            type="text"
            value={
              textContent?.translations?.find(
                ({ languageId }) => languageId === Language.Es,
              )?.translation
            }
            onChange={(e) =>
              updateTranslation(textContent.id, Language.Es, e.target.value)
            }
          />

          <Button
            type="primary"
            disabled={isLoading}
            loading={isLoading}
            onClick={() =>
              updateTextContent(textContent.id, {
                translations:
                  textContent.translations?.map((translation) => ({
                    languageId: translation.languageId,
                    translation: translation.translation,
                  })) || [],
              })
            }
          >
            Update Tag
          </Button>
          <Button
            disabled={isLoading}
            loading={isLoading}
            onClick={() => setShowModal(true)}
          >
            Assign Templates
          </Button>
          <Button
            type="primary"
            danger
            disabled={isLoading}
            loading={isLoading}
            onClick={async () => await deleteTextContent()}
          >
            Delete Tag
          </Button>
        </>
      ) : null}

      <AssignTagToTemplatesModal
        setShowModal={setShowModal}
        showModal={showModal}
        tag={tag}
      />
    </span>
  );
};

export const TemplateTags: FC<Props> = () => {
  const { templateTags, isLoading: tagListingLoading } = useListTemplateTags();
  const { sceneTemplateTagsClient } = useClientsStore((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tagName, setTagName] = useState<undefined | string>();
  const [tagLang, setTagLang] = useState<"en" | "fr" | "es">("fr");

  if (tagListingLoading) return <Spin />;

  const createTag = async (name: string) => {
    try {
      setIsLoading(true);

      await sceneTemplateTagsClient.createSceneTemplateTag({
        body: { name },
        lang: tagLang,
      });
      notification.success({
        message: "The tag was successfully created.",
        duration: 4,
      });
    } catch (err) {
      notification.error({ message: "Could not create tag.", duration: 4 });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Header
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "flex-end",
          justifyContent: "center",
          padding: "0 10px",
        }}
      >
        <Button
          style={{ display: "flex", alignSelf: "flex-end" }}
          onClick={() => setShowModal(true)}
        >
          Create New
        </Button>
      </Header>
      <div style={{ padding: 10 }}>
        <Content
          style={{
            width: "100%",
            padding: 10,
            background: "white",
            border: "1px solid lightgrey",
            borderRadius: 12,
          }}
        >
          {templateTags.map((tag) => (
            <TagLine tag={tag} />
          ))}
        </Content>
      </div>
      <Modal
        confirmLoading={isLoading}
        title="Basic Modal"
        open={showModal}
        onOk={() => {
          if (tagName) createTag(tagName);
        }}
        onCancel={() => setShowModal(false)}
      >
        <Input
          onChange={(e) => setTagName(e.target.value)}
          addonBefore={
            <Select
              disabled
              onChange={(e) => setTagLang(e as "en" | "fr" | "es")}
              defaultValue="en"
            >
              <Option value="en">en</Option>
              <Option value="fr">fr</Option>
              <Option value="es">es</Option>
            </Select>
          }
        />
      </Modal>
    </Space>
  );
};
