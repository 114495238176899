import { useState, useEffect } from "react";
import { Customer } from "@omi-lab/cresus-typescript";

import { useClientsStore } from "../../../store/clients";
import { notification } from "antd";

export function useGetCustomer(organizationId?: string) {
  const [customer, setCustomer] = useState<Customer | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const [customersClient] = useClientsStore((store) => [store.customersClient]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (!organizationId) {
          return;
        }
        const customers = (
          await customersClient.listCustomers({
            organizationId,
          })
        ).data;
        if (customers.length > 0) {
          setCustomer(customers[0]);
        }
      } catch (error: any) {
        notification.error({
          message: error?.response?.data?.error || error?.message || `${error}`,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [organizationId, customersClient]);

  return { customer, setCustomer, isLoading };
}
